import styled from 'styled-components';

const Door = ({info}) => {
    return (
        info[24] ?
            <svg width="44" height="84" viewBox="0 0 44 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M37.9779 23.4031C37.9779 24.6905 37.8657 25.617 37.6704 26.2658C37.4759 26.9117 37.2147 27.2333 36.9491 27.3925C36.6818 27.5527 36.318 27.6039 35.8007 27.513C35.2811 27.4216 34.664 27.1966 33.9421 26.874C33.3397 26.6047 32.6891 26.279 31.9839 25.9259C31.8468 25.8573 31.7076 25.7876 31.5663 25.7171C30.7039 25.2868 29.7722 24.8309 28.7888 24.4153C26.8242 23.5851 24.6248 22.9031 22.2951 22.9031C19.9654 22.9031 17.766 23.5851 15.8014 24.4153C14.818 24.8309 13.8863 25.2868 13.0239 25.7171C12.8826 25.7876 12.7434 25.8573 12.6063 25.926C11.9011 26.279 11.2505 26.6047 10.6481 26.874C9.92624 27.1966 9.30915 27.4216 8.78948 27.513C8.27224 27.6039 7.90842 27.5527 7.64111 27.3925C7.37552 27.2333 7.11434 26.9117 6.91985 26.2658C6.72448 25.617 6.6123 24.6905 6.6123 23.4031C6.6123 13.1292 13.7051 4.94043 22.2951 4.94043C30.8851 4.94043 37.9779 13.1292 37.9779 23.4031Z" fill="white" stroke="black"/>
                <rect x="6.6123" y="23.2754" width="31.3656" height="58.2741" fill="white"/>
                <rect x="6.6123" y="23.2754" width="31.3656" height="58.2741" stroke="black"/>
                <rect x="6.6123" y="23.2754" width="31.3656" height="58.2741" stroke="white" stroke-opacity="0.2"/>
                <path d="M37.9779 23.4031C37.9779 24.6905 37.8657 25.617 37.6704 26.2658C37.4759 26.9117 37.2147 27.2333 36.9491 27.3925C36.6818 27.5527 36.318 27.6039 35.8007 27.513C35.2811 27.4216 34.664 27.1966 33.9421 26.874C33.3397 26.6047 32.6891 26.279 31.9839 25.9259C31.8468 25.8573 31.7076 25.7876 31.5663 25.7171C30.7039 25.2868 29.7722 24.8309 28.7888 24.4153C26.8242 23.5851 24.6248 22.9031 22.2951 22.9031C19.9654 22.9031 17.766 23.5851 15.8014 24.4153C14.818 24.8309 13.8863 25.2868 13.0239 25.7171C12.8826 25.7876 12.7434 25.8573 12.6063 25.926C11.9011 26.279 11.2505 26.6047 10.6481 26.874C9.92624 27.1966 9.30915 27.4216 8.78948 27.513C8.27224 27.6039 7.90842 27.5527 7.64111 27.3925C7.37552 27.2333 7.11434 26.9117 6.91985 26.2658C6.72448 25.617 6.6123 24.6905 6.6123 23.4031C6.6123 13.1292 13.7051 4.94043 22.2951 4.94043C30.8851 4.94043 37.9779 13.1292 37.9779 23.4031Z" fill="white" stroke="black"/>
                <rect x="6.6123" y="23.2754" width="31.3656" height="58.2741" fill="white"/>
                <rect x="6.6123" y="23.2754" width="31.3656" height="58.2741" stroke="black"/>
                <rect x="6.6123" y="23.2754" width="31.3656" height="58.2741" stroke="white" stroke-opacity="0.2"/>
                <path d="M21.089 10.1068L19.6458 1.17285H24.5789L23.4329 10.1068H21.089Z" fill="white" stroke="black"/>
                <path d="M37.7466 81.4812V23.5671L43.295 21.4044V83.0158L37.7466 81.4812Z" fill="white" stroke="black"/>
                <path d="M6.84326 81.7744V23.1277L1.29492 21.3572V83.0454L6.84326 81.7744Z" fill="white" stroke="black"/>
                <rect x="15.6772" y="50.1484" width="13.2353" height="7.84002" fill="#AF2010"/>
                <path d="M22.2949 55.0371L15.7997 50.2639H28.7901L22.2949 55.0371Z" fill="#005452"/>
                <path d="M0.794922 0.672852H43.7949V42.1729V83.6729H0.794922V0.672852Z" fill="#FFF9C4" fill-opacity="0.25"/>
            </svg>        
        :
            <svg width="46" height="86" viewBox="0 0 46 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.0177 24.3712C39.0177 25.716 38.9005 26.6855 38.6957 27.3657C38.4918 28.043 38.2167 28.3842 37.9331 28.5542C37.6479 28.7252 37.2621 28.7782 36.7194 28.6828C36.1742 28.5869 35.5281 28.3512 34.7742 28.0142C34.1449 27.7328 33.4652 27.3926 32.7292 27.0241C32.5861 26.9525 32.4408 26.8798 32.2934 26.8062C31.3933 26.3571 30.4213 25.8814 29.3955 25.4479C27.3461 24.5819 25.0533 23.8712 22.6258 23.8712C20.1982 23.8712 17.9055 24.5819 15.8561 25.4479C14.8303 25.8814 13.8583 26.3571 12.9581 26.8062C12.8107 26.8798 12.6655 26.9525 12.5224 27.0241C11.7863 27.3926 11.1067 27.7328 10.4774 28.0142C9.72343 28.3512 9.07732 28.5869 8.53217 28.6828C7.98944 28.7782 7.60367 28.7252 7.31842 28.5542C7.03488 28.3842 6.75977 28.043 6.55584 27.3657C6.35102 26.6855 6.23389 25.716 6.23389 24.3712C6.23389 13.6384 13.6442 5.07764 22.6258 5.07764C31.6074 5.07764 39.0177 13.6384 39.0177 24.3712Z" fill="white" stroke="black"/>
                <rect x="6.23389" y="24.2158" width="32.7838" height="60.8714" fill="white"/>
                <rect x="6.23389" y="24.2158" width="32.7838" height="60.8714" stroke="black"/>
                <rect x="6.23389" y="24.2158" width="32.7838" height="60.8714" stroke="white" stroke-opacity="0.2"/>
                <path d="M22.8607 83.5129L7.07935 61.4654L22.8608 40.8103L38.178 61.4653L22.8607 83.5129Z" fill="white" stroke="#BAB8B5"/>
                <path d="M21.3478 10.5143L19.8343 1.14502H25.0342L23.8323 10.5143H21.3478Z" fill="white" stroke="black"/>
                <rect x="6" width="34" height="86" fill="black" fill-opacity="0.3"/>
                <path d="M22.3782 40.8732L23.3598 40.8499C24.0705 36.6265 25.2128 33.4372 26.5087 31.207C27.8112 28.9654 29.2366 27.7446 30.4881 27.3513C31.1061 27.1571 31.6834 27.1628 32.2045 27.3414C32.7275 27.5207 33.2261 27.8855 33.6637 28.461C34.5464 29.6219 35.1563 31.6128 35.1563 34.4439C35.1563 40.1181 33.1865 46.4564 30.6047 51.8692C28.1415 57.0333 25.1613 61.2723 22.8919 63.2594C20.7093 61.0691 17.7178 56.8091 15.2247 51.6995C12.6095 46.3395 10.5771 40.1139 10.5771 34.4439C10.5771 31.6049 11.1411 29.6287 11.9665 28.4895C12.3747 27.9262 12.838 27.5784 13.3225 27.4123C13.8048 27.2469 14.3473 27.2481 14.9416 27.4489C16.1498 27.8569 17.5548 29.0891 18.8981 31.3266C20.2332 33.5506 21.4729 36.7126 22.3782 40.8732Z" stroke="#BAB8B5"/>
                <line x1="23.0255" y1="63.0862" x2="30.2649" y2="72.7387" stroke="#BAB8B5"/>
                <line x1="22.995" y1="63.7185" x2="15.294" y2="73.3508" stroke="#BAB8B5"/>
                <path d="M15.2508 60.2124H22.1508V59.0424H19.1058C18.5508 59.0424 17.8908 59.0874 17.3058 59.1474C19.9008 56.7024 21.6258 54.4674 21.6258 52.2624C21.6258 50.2974 20.3958 49.0224 18.4308 49.0224C17.0358 49.0224 16.0758 49.6674 15.1758 50.6424L15.9708 51.4074C16.5858 50.6874 17.3658 50.1324 18.2658 50.1324C19.6308 50.1324 20.2758 51.0624 20.2758 52.3224C20.2758 54.1974 18.7008 56.4024 15.2508 59.4174V60.2124ZM27.9961 60.2124H29.2861V49.2174H27.7711L23.1961 56.2824V57.1824H30.7561V56.1024H24.6361L27.1411 52.3524C27.4411 51.8124 27.7411 51.2574 28.0111 50.7174H28.0861C28.0411 51.2874 27.9961 52.1724 27.9961 52.7274V60.2124Z" fill="#DADADA"/>
            </svg>
    )
}

export default Door;

const WriterNickname = styled.p `
    margin-bottom: 10px;
    font-size: 12px;
    color: #6E6E6E;
`
