import styled from 'styled-components';
import {dbService, authService, storageService} from "fbase"
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';


const Window = ({info, item}) => {
    return (
        info[item] ? 
        <svg width="45" height="55" viewBox="0 0 45 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.7979 14.161L44.5 11.1959L44.5 32.234V53.3396L38.7979 50.9135L38.7979 14.161Z" fill="white" stroke="black"/>
            <path d="M6.20213 51.1046L0.5 54.0698V33.0316V11.926L6.20213 14.3521V51.1046Z" fill="white" stroke="black"/>
            <path d="M37.9575 14.2987C37.9575 15.0851 37.8496 15.6199 37.6811 15.9768C37.5212 16.3156 37.3029 16.5047 37.0249 16.611C36.7269 16.7249 36.3237 16.757 35.788 16.6969C35.2557 16.6372 34.6339 16.4917 33.9208 16.2884C33.3396 16.1226 32.7035 15.9193 32.0204 15.7009C29.3934 14.861 26.0708 13.7987 22.5001 13.7987C18.9294 13.7987 15.6067 14.861 12.9797 15.7009C12.2966 15.9193 11.6605 16.1226 11.0793 16.2884C10.3662 16.4917 9.74439 16.6372 9.21215 16.6969C8.67636 16.757 8.27317 16.7249 7.97522 16.611C7.6972 16.5047 7.47891 16.3156 7.31896 15.9768C7.15051 15.6199 7.0426 15.0851 7.0426 14.2987C7.0426 11.1981 8.7227 8.35231 11.5158 6.26439C14.309 4.17641 18.1909 2.87012 22.5001 2.87012C26.8092 2.87012 30.6911 4.17641 33.4843 6.26439C36.2774 8.35231 37.9575 11.1981 37.9575 14.2987Z" fill="white" stroke="black"/>
            <path d="M35.4296 16.9802C35.4296 18.6077 35.0519 19.1305 34.6726 19.3037C34.4554 19.403 34.1494 19.4368 33.716 19.3787C33.2848 19.3209 32.7749 19.1791 32.1806 18.9765C31.7022 18.8135 31.1758 18.6123 30.6084 18.3955C28.41 17.5553 25.5968 16.4802 22.5791 16.4802C19.5613 16.4802 16.7481 17.5553 14.5497 18.3955C13.9823 18.6123 13.4559 18.8135 12.9775 18.9765C12.3832 19.1791 11.8733 19.3209 11.4422 19.3787C11.0087 19.4368 10.7027 19.403 10.4855 19.3037C10.1062 19.1305 9.72852 18.6077 9.72852 16.9802C9.72852 10.7222 15.4273 5.55566 22.5791 5.55566C29.7308 5.55566 35.4296 10.7222 35.4296 16.9802Z" fill="white" stroke="black"/>
            <rect x="7.0426" y="14.4033" width="30.9149" height="36.2867" fill="white" stroke="black"/>
            <rect x="9.57056" y="17.2471" width="25.8591" height="30.5989" fill="white" stroke="black"/>
            <path d="M21.5063 9.29566L20.0854 0.5H24.9337L23.8054 9.29566H21.5063Z" fill="white" stroke="black"/>
            <rect x="15.8823" y="29.4756" width="13.2353" height="7.84002" fill="#E6B38E"/>
            <path d="M22.5 34.3643L16.0048 29.5911H28.9952L22.5 34.3643Z" fill="#DF6F6F"/>
            <rect width="45" height="53.0526" fill="#FFF9C4" fill-opacity="0.25"/>
        </svg>
        :        
            item == 1 ? 
            <svg width="33" height="53" viewBox="0 0 33 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.0968 14.6043C32.0968 15.4084 31.9865 15.9563 31.8135 16.3228C31.649 16.6713 31.424 16.8664 31.1373 16.976C30.8308 17.0933 30.417 17.1259 29.869 17.0644C29.3245 17.0033 28.6888 16.8546 27.9602 16.6468C27.3661 16.4774 26.7161 16.2695 26.0181 16.0464C23.335 15.1886 19.9435 14.1043 16.2984 14.1043C12.6533 14.1043 9.26179 15.1886 6.57869 16.0464C5.88071 16.2695 5.23067 16.4774 4.63657 16.6468C3.90798 16.8546 3.27227 17.0033 2.72777 17.0644C2.17973 17.1259 1.76599 17.0933 1.45941 16.976C1.17276 16.8664 0.947776 16.6713 0.783287 16.3228C0.610292 15.9563 0.5 15.4084 0.5 14.6043C0.5 11.4334 2.21825 8.52385 5.07305 6.38982C7.92794 4.25572 11.895 2.9209 16.2984 2.9209C20.7017 2.9209 24.6688 4.25572 27.5237 6.38982C30.3785 8.52385 32.0968 11.4334 32.0968 14.6043Z" fill="white" stroke="black"/>
            <path d="M29.5148 17.3433C29.5148 19.006 29.1291 19.5464 28.7355 19.7263C28.511 19.8288 28.1962 19.8632 27.7525 19.8037C27.3111 19.7445 26.7895 19.5994 26.1823 19.3925C25.6931 19.2258 25.155 19.0201 24.5752 18.7986C22.3299 17.9405 19.459 16.8433 16.3791 16.8433C13.2991 16.8433 10.4282 17.9405 8.1829 18.7986C7.60312 19.0201 7.06504 19.2258 6.57585 19.3925C5.96858 19.5994 5.44702 19.7445 5.00564 19.8037C4.56191 19.8632 4.2471 19.8288 4.02266 19.7263C3.62899 19.5464 3.24329 19.006 3.24329 17.3433C3.24329 10.9447 9.06976 5.66406 16.3791 5.66406C23.6883 5.66406 29.5148 10.9447 29.5148 17.3433Z" fill="white" stroke="black"/>
            <rect x="0.5" y="14.7002" width="31.5968" height="37.0833" fill="white" stroke="black"/>
            <rect x="3.08191" y="17.6055" width="26.4329" height="31.274" fill="white" stroke="black"/>
            <line x1="20.6158" y1="32.2979" x2="29.8536" y2="32.2979" stroke="black"/>
            <line x1="15.8381" y1="28.4023" x2="15.8381" y2="17.1055" stroke="black"/>
            <line x1="15.9597" y1="49.2178" x2="15.9597" y2="37.115" stroke="black"/>
            <line x1="2.58191" y1="32.4199" x2="11.9414" y2="32.4199" stroke="black"/>
            <rect x="12.4871" y="32.8037" width="5.4548" height="5.4548" transform="rotate(-45 12.4871 32.8037)" fill="white" stroke="black"/>
            <path d="M15.2743 9.50494L13.8196 0.5H18.7962L17.641 9.50494H15.2743Z" fill="white" stroke="black"/>
            <path d="M0 0H32.5V52.2402H0V0Z" fill="black" fill-opacity="0.3"/>
            <path d="M13.32 38H19.35V36.86H17.13V27.005H16.08C15.495 27.365 14.79 27.62 13.8 27.785V28.67H15.765V36.86H13.32V38Z" fill="#DADADA"/>
            </svg>
            : item == 2 ?
            <svg width="35" height="55" viewBox="0 0 35 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.6505 15.6686C33.6505 16.5033 33.536 17.0742 33.3551 17.4574C33.1827 17.8227 32.9461 18.0283 32.6444 18.1437C32.3228 18.2666 31.8907 18.3002 31.3214 18.2364C30.7555 18.1729 30.0955 18.0184 29.3401 17.8029C28.7235 17.6271 28.0492 17.4115 27.3253 17.1801C24.5446 16.2912 21.0333 15.1686 17.2586 15.1686C13.4839 15.1686 9.97258 16.2912 7.19186 17.1801C6.46802 17.4115 5.79369 17.6271 5.1771 17.8029C4.42167 18.0184 3.76166 18.1729 3.19583 18.2364C2.62646 18.3002 2.19435 18.2666 1.87275 18.1437C1.57108 18.0283 1.33444 17.8227 1.16204 17.4574C0.981143 17.0742 0.866699 16.5033 0.866699 15.6686C0.866699 12.3751 2.65138 9.35469 5.61358 7.14037C8.57588 4.92598 12.6914 3.5415 17.2586 3.5415C21.8258 3.5415 25.9413 4.92598 28.9036 7.14037C31.8658 9.35469 33.6505 12.3751 33.6505 15.6686Z" fill="white" stroke="black"/>
            <path d="M30.9746 18.5075C30.9746 20.2315 30.575 20.8025 30.1562 20.9938C29.9192 21.1021 29.589 21.1374 29.1275 21.0756C28.6682 21.014 28.1264 20.8631 27.4966 20.6485C26.9886 20.4754 26.4302 20.262 25.8288 20.0322C23.5018 19.143 20.5307 18.0075 17.3423 18.0075C14.1539 18.0075 11.1828 19.143 8.85578 20.0322C8.25436 20.262 7.69596 20.4754 7.18796 20.6485C6.55822 20.8631 6.01635 21.014 5.55712 21.0756C5.09555 21.1374 4.76536 21.1021 4.52836 20.9938C4.10956 20.8025 3.70996 20.2315 3.70996 18.5075C3.70996 11.8639 9.75875 6.38477 17.3423 6.38477C24.9258 6.38477 30.9746 11.8639 30.9746 18.5075Z" fill="white" stroke="black"/>
            <rect x="0.866699" y="15.7505" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.54248" y="18.7607" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.7334" y1="34.0249" x2="31.3076" y2="34.0249" stroke="black"/>
            <line x1="16.7998" y1="29.9688" x2="16.7998" y2="18.2605" stroke="black"/>
            <line x1="16.9258" y1="51.543" x2="16.9258" y2="38.9995" stroke="black"/>
            <line x1="3.04248" y1="34.1509" x2="12.7428" y2="34.1509" stroke="black"/>
            <rect x="13.2828" y="34.5312" width="5.68986" height="5.68986" transform="rotate(-45 13.2828 34.5312)" fill="white" stroke="black"/>
            <path d="M16.1818 10.402L14.6683 1.03271H19.8682L18.6663 10.402H16.1818Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M13.2508 39.2124H20.1508V38.0424H17.1058C16.5508 38.0424 15.8908 38.0874 15.3058 38.1474C17.9008 35.7024 19.6258 33.4674 19.6258 31.2624C19.6258 29.2974 18.3958 28.0224 16.4308 28.0224C15.0358 28.0224 14.0758 28.6674 13.1758 29.6424L13.9708 30.4074C14.5858 29.6874 15.3658 29.1324 16.2658 29.1324C17.6308 29.1324 18.2758 30.0624 18.2758 31.3224C18.2758 33.1974 16.7008 35.4024 13.2508 38.4174V39.2124Z" fill="#DADADA"/>
            </svg>
            : item == 3 ?
            <svg width="34" height="55" viewBox="0 0 34 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.3844 15.6686C33.3844 16.5033 33.2699 17.0742 33.089 17.4574C32.9166 17.8227 32.68 18.0283 32.3783 18.1437C32.0567 18.2666 31.6246 18.3002 31.0552 18.2364C30.4894 18.1729 29.8294 18.0184 29.074 17.8029C28.4574 17.6271 27.783 17.4115 27.0592 17.1801C24.2785 16.2912 20.7672 15.1686 16.9925 15.1686C13.2178 15.1686 9.70647 16.2912 6.92575 17.1801C6.20191 17.4115 5.52757 17.6271 4.91099 17.8029C4.15555 18.0184 3.49555 18.1729 2.92972 18.2364C2.36035 18.3002 1.92823 18.2666 1.60664 18.1437C1.30497 18.0283 1.06833 17.8227 0.895931 17.4574C0.715029 17.0742 0.600586 16.5033 0.600586 15.6686C0.600586 12.3751 2.38527 9.35469 5.34747 7.14037C8.30977 4.92598 12.4252 3.5415 16.9925 3.5415C21.5597 3.5415 25.6752 4.92598 28.6375 7.14037C31.5997 9.35469 33.3844 12.3751 33.3844 15.6686Z" fill="white" stroke="black"/>
            <path d="M30.7085 18.5075C30.7085 20.2315 30.3089 20.8025 29.8901 20.9938C29.6531 21.1021 29.3229 21.1374 28.8613 21.0756C28.4021 21.014 27.8602 20.8631 27.2305 20.6485C26.7225 20.4754 26.1641 20.262 25.5627 20.0322C23.2357 19.143 20.2645 18.0075 17.0762 18.0075C13.8878 18.0075 10.9167 19.143 8.58967 20.0322C7.98824 20.262 7.42985 20.4754 6.92185 20.6485C6.29211 20.8631 5.75023 21.014 5.291 21.0756C4.82943 21.1374 4.49925 21.1021 4.26224 20.9938C3.84345 20.8025 3.44385 20.2315 3.44385 18.5075C3.44385 11.8639 9.49264 6.38477 17.0762 6.38477C24.6597 6.38477 30.7085 11.8639 30.7085 18.5075Z" fill="white" stroke="black"/>
            <rect x="0.600586" y="15.7505" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.27637" y="18.7607" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.4673" y1="34.0249" x2="31.0415" y2="34.0249" stroke="black"/>
            <line x1="16.5337" y1="29.9688" x2="16.5337" y2="18.2605" stroke="black"/>
            <line x1="16.6597" y1="51.543" x2="16.6597" y2="38.9995" stroke="black"/>
            <line x1="2.77637" y1="34.1509" x2="12.4767" y2="34.1509" stroke="black"/>
            <rect x="13.0167" y="34.5312" width="5.68986" height="5.68986" transform="rotate(-45 13.0167 34.5312)" fill="white" stroke="black"/>
            <path d="M15.9157 10.402L14.4022 1.03271H19.6021L18.4002 10.402H15.9157Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M16.5358 39.4224C18.5008 39.4224 20.0758 38.2374 20.0758 36.2874C20.0758 34.7724 19.0258 33.7974 17.7358 33.4824V33.4224C18.9058 33.0024 19.6858 32.1174 19.6858 30.7824C19.6858 29.0274 18.3358 28.0224 16.4908 28.0224C15.2308 28.0224 14.2558 28.5774 13.4308 29.3274L14.1658 30.2124C14.7808 29.5824 15.5608 29.1324 16.4458 29.1324C17.5858 29.1324 18.2908 29.8224 18.2908 30.8724C18.2908 32.0724 17.5258 32.9724 15.2608 32.9724V34.0374C17.7958 34.0374 18.6808 34.9074 18.6808 36.2274C18.6808 37.4874 17.7508 38.2824 16.4458 38.2824C15.1858 38.2824 14.3608 37.6824 13.7158 37.0074L13.0258 37.9074C13.7458 38.7024 14.8108 39.4224 16.5358 39.4224Z" fill="#DADADA"/>
            </svg>
            : item == 4 ?
            <svg width="35" height="55" viewBox="0 0 35 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.7511 15.6686C33.7511 16.5033 33.6366 17.0742 33.4557 17.4574C33.2833 17.8227 33.0467 18.0283 32.745 18.1437C32.4234 18.2666 31.9913 18.3002 31.4219 18.2364C30.8561 18.1729 30.1961 18.0184 29.4407 17.8029C28.8241 17.6271 28.1497 17.4115 27.4259 17.1801C24.6452 16.2912 21.1339 15.1686 17.3592 15.1686C13.5845 15.1686 10.0732 16.2912 7.29245 17.1801C6.56861 17.4115 5.89427 17.6271 5.27769 17.8029C4.52225 18.0184 3.86224 18.1729 3.29642 18.2364C2.72705 18.3002 2.29493 18.2666 1.97334 18.1437C1.67167 18.0283 1.43503 17.8227 1.26263 17.4574C1.08173 17.0742 0.967285 16.5033 0.967285 15.6686C0.967285 12.3751 2.75197 9.35469 5.71417 7.14037C8.67647 4.92598 12.7919 3.5415 17.3592 3.5415C21.9264 3.5415 26.0419 4.92598 29.0042 7.14037C31.9664 9.35469 33.7511 12.3751 33.7511 15.6686Z" fill="white" stroke="black"/>
            <path d="M31.0752 18.5075C31.0752 20.2315 30.6756 20.8025 30.2568 20.9938C30.0198 21.1021 29.6896 21.1374 29.228 21.0756C28.7688 21.014 28.2269 20.8631 27.5972 20.6485C27.0892 20.4754 26.5308 20.262 25.9294 20.0322C23.6024 19.143 20.6312 18.0075 17.4429 18.0075C14.2545 18.0075 11.2834 19.143 8.95637 20.0322C8.35494 20.262 7.79655 20.4754 7.28854 20.6485C6.65881 20.8631 6.11693 21.014 5.6577 21.0756C5.19613 21.1374 4.86595 21.1021 4.62894 20.9938C4.21015 20.8025 3.81055 20.2315 3.81055 18.5075C3.81055 11.8639 9.85934 6.38477 17.4429 6.38477C25.0264 6.38477 31.0752 11.8639 31.0752 18.5075Z" fill="white" stroke="black"/>
            <rect x="0.967285" y="15.7505" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.64307" y="18.7607" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.834" y1="34.0249" x2="31.4082" y2="34.0249" stroke="black"/>
            <line x1="16.9004" y1="29.9688" x2="16.9004" y2="18.2605" stroke="black"/>
            <line x1="17.0264" y1="51.543" x2="17.0264" y2="38.9995" stroke="black"/>
            <line x1="3.14307" y1="34.1509" x2="12.8434" y2="34.1509" stroke="black"/>
            <rect x="13.3834" y="34.5312" width="5.68986" height="5.68986" transform="rotate(-45 13.3834 34.5312)" fill="white" stroke="black"/>
            <path d="M16.2824 10.402L14.7689 1.03271H19.9688L18.7669 10.402H16.2824Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M18.6758 39.2124H19.9658V28.2174H18.4508L13.8758 35.2824V36.1824H21.4358V35.1024H15.3158L17.8208 31.3524C18.1208 30.8124 18.4208 30.2574 18.6908 29.7174H18.7658C18.7208 30.2874 18.6758 31.1724 18.6758 31.7274V39.2124Z" fill="#DADADA"/>
            </svg>
            : item == 5 ?
            <svg width="34" height="56" viewBox="0 0 34 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.2838 16.0319C33.2838 16.8665 33.1693 17.4374 32.9884 17.8207C32.816 18.1859 32.5794 18.3916 32.2777 18.5069C31.9561 18.6299 31.524 18.6635 30.9546 18.5996C30.3888 18.5362 29.7288 18.3816 28.9734 18.1662C28.3568 17.9904 27.6825 17.7748 26.9586 17.5434C24.1779 16.6544 20.6666 15.5319 16.8919 15.5319C13.1172 15.5319 9.60588 16.6544 6.82516 17.5434C6.10132 17.7748 5.42699 17.9904 4.8104 18.1662C4.05497 18.3816 3.39496 18.5362 2.82913 18.5996C2.25976 18.6635 1.82765 18.6299 1.50605 18.5069C1.20438 18.3916 0.96774 18.1859 0.795345 17.8207C0.614444 17.4374 0.5 16.8665 0.5 16.0319C0.5 12.7384 2.28469 9.71797 5.24689 7.50365C8.20918 5.28927 12.3247 3.90479 16.8919 3.90479C21.4591 3.90479 25.5746 5.28927 28.5369 7.50365C31.4991 9.71797 33.2838 12.7384 33.2838 16.0319Z" fill="white" stroke="black"/>
            <path d="M30.6079 18.8708C30.6079 20.5947 30.2083 21.1657 29.7895 21.3571C29.5525 21.4654 29.2223 21.5007 28.7608 21.4388C28.3015 21.3773 27.7597 21.2264 27.1299 21.0118C26.6219 20.8387 26.0635 20.6253 25.4621 20.3955C23.1351 19.5062 20.164 18.3708 16.9756 18.3708C13.7872 18.3708 10.8161 19.5062 8.48908 20.3955C7.88766 20.6253 7.32926 20.8387 6.82126 21.0118C6.19152 21.2264 5.64965 21.3773 5.19042 21.4388C4.72885 21.5007 4.39866 21.4654 4.16166 21.3571C3.74286 21.1657 3.34326 20.5947 3.34326 18.8708C3.34326 12.2272 9.39206 6.74805 16.9756 6.74805C24.5591 6.74805 30.6079 12.2272 30.6079 18.8708Z" fill="white" stroke="black"/>
            <rect x="0.5" y="16.1138" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.17578" y="19.124" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.3667" y1="34.3882" x2="30.9409" y2="34.3882" stroke="black"/>
            <line x1="16.4331" y1="30.332" x2="16.4331" y2="18.6238" stroke="black"/>
            <line x1="16.5591" y1="51.9062" x2="16.5591" y2="39.3628" stroke="black"/>
            <line x1="2.67578" y1="34.5142" x2="12.3761" y2="34.5142" stroke="black"/>
            <rect x="12.9161" y="34.8945" width="5.68986" height="5.68986" transform="rotate(-45 12.9161 34.8945)" fill="white" stroke="black"/>
            <path d="M15.8151 10.7653L14.3016 1.396H19.5015L18.2996 10.7653H15.8151Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M16.5058 40.4224C18.3508 40.4224 20.1058 39.0424 20.1058 36.6424C20.1058 34.2124 18.6208 33.1324 16.7908 33.1324C16.1308 33.1324 15.6358 33.2974 15.1408 33.5674L15.4258 30.3874H19.5808V29.2174H14.2258L13.8808 34.3624L14.6158 34.8274C15.2458 34.4074 15.7108 34.1674 16.4458 34.1674C17.8108 34.1674 18.7108 35.1124 18.7108 36.6874C18.7108 38.2774 17.6758 39.2824 16.3708 39.2824C15.1108 39.2824 14.3008 38.6974 13.6858 38.0524L12.9958 38.9524C13.7458 39.7024 14.7958 40.4224 16.5058 40.4224Z" fill="#DADADA"/>
            </svg>
            : item == 6 ?
            <svg width="35" height="56" viewBox="0 0 35 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.6505 16.0319C33.6505 16.8665 33.536 17.4374 33.3551 17.8207C33.1827 18.1859 32.9461 18.3916 32.6444 18.5069C32.3228 18.6299 31.8907 18.6635 31.3214 18.5996C30.7555 18.5362 30.0955 18.3816 29.3401 18.1662C28.7235 17.9904 28.0492 17.7748 27.3253 17.5434C24.5446 16.6544 21.0333 15.5319 17.2586 15.5319C13.4839 15.5319 9.97258 16.6544 7.19186 17.5434C6.46802 17.7748 5.79369 17.9904 5.1771 18.1662C4.42167 18.3816 3.76166 18.5362 3.19583 18.5996C2.62646 18.6635 2.19435 18.6299 1.87275 18.5069C1.57108 18.3916 1.33444 18.1859 1.16204 17.8207C0.981143 17.4374 0.866699 16.8665 0.866699 16.0319C0.866699 12.7384 2.65138 9.71797 5.61358 7.50365C8.57588 5.28927 12.6914 3.90479 17.2586 3.90479C21.8258 3.90479 25.9413 5.28927 28.9036 7.50365C31.8658 9.71797 33.6505 12.7384 33.6505 16.0319Z" fill="white" stroke="black"/>
            <path d="M30.9746 18.8708C30.9746 20.5947 30.575 21.1657 30.1562 21.3571C29.9192 21.4654 29.589 21.5007 29.1275 21.4388C28.6682 21.3773 28.1264 21.2264 27.4966 21.0118C26.9886 20.8387 26.4302 20.6253 25.8288 20.3955C23.5018 19.5062 20.5307 18.3708 17.3423 18.3708C14.1539 18.3708 11.1828 19.5062 8.85578 20.3955C8.25436 20.6253 7.69596 20.8387 7.18796 21.0118C6.55822 21.2264 6.01635 21.3773 5.55712 21.4388C5.09555 21.5007 4.76536 21.4654 4.52836 21.3571C4.10956 21.1657 3.70996 20.5947 3.70996 18.8708C3.70996 12.2272 9.75875 6.74805 17.3423 6.74805C24.9258 6.74805 30.9746 12.2272 30.9746 18.8708Z" fill="white" stroke="black"/>
            <rect x="0.866699" y="16.1138" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.54248" y="19.124" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.7334" y1="34.3882" x2="31.3076" y2="34.3882" stroke="black"/>
            <line x1="16.7998" y1="30.332" x2="16.7998" y2="18.6238" stroke="black"/>
            <line x1="16.9258" y1="51.9062" x2="16.9258" y2="39.3628" stroke="black"/>
            <line x1="3.04248" y1="34.5142" x2="12.7428" y2="34.5142" stroke="black"/>
            <rect x="13.2828" y="34.8945" width="5.68986" height="5.68986" transform="rotate(-45 13.2828 34.8945)" fill="white" stroke="black"/>
            <path d="M16.1818 10.7653L14.6683 1.396H19.8682L18.6663 10.7653H16.1818Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M17.1058 40.4224C18.8008 40.4224 20.2558 38.9824 20.2558 36.8524C20.2558 34.5424 19.0558 33.4024 17.1958 33.4024C16.3108 33.4024 15.2908 33.9424 14.6158 34.8274L14.6608 35.9224C15.3958 34.8274 16.2808 34.4074 16.9858 34.4074C18.3058 34.4074 18.9658 35.3524 18.9658 36.8524C18.9658 38.3374 18.1558 39.3424 17.1058 39.3424C15.5608 39.3424 14.7058 37.7974 14.7058 34.9774C14.7058 31.3774 15.9958 30.1624 17.5408 30.1624C18.2008 30.1624 18.8758 30.4774 19.2958 30.9874L20.0608 30.1474C19.4608 29.5024 18.6208 29.0224 17.4808 29.0224C15.3508 29.0224 13.4158 30.6724 13.4158 34.9774C13.4158 38.6074 14.9908 40.4224 17.1058 40.4224Z" fill="#DADADA"/>
            </svg>
            : item == 7 ?
            <svg width="34" height="56" viewBox="0 0 34 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.3844 16.0319C33.3844 16.8665 33.2699 17.4374 33.089 17.8207C32.9166 18.1859 32.68 18.3916 32.3783 18.5069C32.0567 18.6299 31.6246 18.6635 31.0552 18.5996C30.4894 18.5362 29.8294 18.3816 29.074 18.1662C28.4574 17.9904 27.783 17.7748 27.0592 17.5434C24.2785 16.6544 20.7672 15.5319 16.9925 15.5319C13.2178 15.5319 9.70647 16.6544 6.92575 17.5434C6.20191 17.7748 5.52757 17.9904 4.91099 18.1662C4.15555 18.3816 3.49555 18.5362 2.92972 18.5996C2.36035 18.6635 1.92823 18.6299 1.60664 18.5069C1.30497 18.3916 1.06833 18.1859 0.895931 17.8207C0.715029 17.4374 0.600586 16.8665 0.600586 16.0319C0.600586 12.7384 2.38527 9.71797 5.34747 7.50365C8.30977 5.28927 12.4252 3.90479 16.9925 3.90479C21.5597 3.90479 25.6752 5.28927 28.6375 7.50365C31.5997 9.71797 33.3844 12.7384 33.3844 16.0319Z" fill="white" stroke="black"/>
            <path d="M30.7085 18.8708C30.7085 20.5947 30.3089 21.1657 29.8901 21.3571C29.6531 21.4654 29.3229 21.5007 28.8613 21.4388C28.4021 21.3773 27.8602 21.2264 27.2305 21.0118C26.7225 20.8387 26.1641 20.6253 25.5627 20.3955C23.2357 19.5062 20.2645 18.3708 17.0762 18.3708C13.8878 18.3708 10.9167 19.5062 8.58967 20.3955C7.98824 20.6253 7.42985 20.8387 6.92185 21.0118C6.29211 21.2264 5.75023 21.3773 5.291 21.4388C4.82943 21.5007 4.49925 21.4654 4.26224 21.3571C3.84345 21.1657 3.44385 20.5947 3.44385 18.8708C3.44385 12.2272 9.49264 6.74805 17.0762 6.74805C24.6597 6.74805 30.7085 12.2272 30.7085 18.8708Z" fill="white" stroke="black"/>
            <rect x="0.600586" y="16.1138" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.27637" y="19.124" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.4673" y1="34.3882" x2="31.0415" y2="34.3882" stroke="black"/>
            <line x1="16.5337" y1="30.332" x2="16.5337" y2="18.6238" stroke="black"/>
            <line x1="16.6597" y1="51.9062" x2="16.6597" y2="39.3628" stroke="black"/>
            <line x1="2.77637" y1="34.5142" x2="12.4767" y2="34.5142" stroke="black"/>
            <rect x="13.0167" y="34.8945" width="5.68986" height="5.68986" transform="rotate(-45 13.0167 34.8945)" fill="white" stroke="black"/>
            <path d="M15.9157 10.7653L14.4022 1.396H19.6021L18.4002 10.7653H15.9157Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M15.5458 40.2124H16.9708C17.1508 35.9224 17.6158 33.3574 20.1958 30.0424V29.2174H13.3258V30.3874H18.6508C16.4908 33.3874 15.7408 36.0574 15.5458 40.2124Z" fill="#DADADA"/>
            </svg>
            : item == 8 ?
            <svg width="35" height="56" viewBox="0 0 35 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.7511 16.0319C33.7511 16.8665 33.6366 17.4374 33.4557 17.8207C33.2833 18.1859 33.0467 18.3916 32.745 18.5069C32.4234 18.6299 31.9913 18.6635 31.4219 18.5996C30.8561 18.5362 30.1961 18.3816 29.4407 18.1662C28.8241 17.9904 28.1497 17.7748 27.4259 17.5434C24.6452 16.6544 21.1339 15.5319 17.3592 15.5319C13.5845 15.5319 10.0732 16.6544 7.29245 17.5434C6.56861 17.7748 5.89427 17.9904 5.27769 18.1662C4.52225 18.3816 3.86224 18.5362 3.29642 18.5996C2.72705 18.6635 2.29493 18.6299 1.97334 18.5069C1.67167 18.3916 1.43503 18.1859 1.26263 17.8207C1.08173 17.4374 0.967285 16.8665 0.967285 16.0319C0.967285 12.7384 2.75197 9.71797 5.71417 7.50365C8.67647 5.28927 12.7919 3.90479 17.3592 3.90479C21.9264 3.90479 26.0419 5.28927 29.0042 7.50365C31.9664 9.71797 33.7511 12.7384 33.7511 16.0319Z" fill="white" stroke="black"/>
            <path d="M31.0752 18.8708C31.0752 20.5947 30.6756 21.1657 30.2568 21.3571C30.0198 21.4654 29.6896 21.5007 29.228 21.4388C28.7688 21.3773 28.2269 21.2264 27.5972 21.0118C27.0892 20.8387 26.5308 20.6253 25.9294 20.3955C23.6024 19.5062 20.6312 18.3708 17.4429 18.3708C14.2545 18.3708 11.2834 19.5062 8.95637 20.3955C8.35494 20.6253 7.79655 20.8387 7.28854 21.0118C6.65881 21.2264 6.11693 21.3773 5.6577 21.4388C5.19613 21.5007 4.86595 21.4654 4.62894 21.3571C4.21015 21.1657 3.81055 20.5947 3.81055 18.8708C3.81055 12.2272 9.85934 6.74805 17.4429 6.74805C25.0264 6.74805 31.0752 12.2272 31.0752 18.8708Z" fill="white" stroke="black"/>
            <rect x="0.967285" y="16.1138" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.64307" y="19.124" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.834" y1="34.3882" x2="31.4082" y2="34.3882" stroke="black"/>
            <line x1="16.9004" y1="30.332" x2="16.9004" y2="18.6238" stroke="black"/>
            <line x1="17.0264" y1="51.9062" x2="17.0264" y2="39.3628" stroke="black"/>
            <line x1="3.14307" y1="34.5142" x2="12.8434" y2="34.5142" stroke="black"/>
            <rect x="13.3834" y="34.8945" width="5.68986" height="5.68986" transform="rotate(-45 13.3834 34.8945)" fill="white" stroke="black"/>
            <path d="M16.2824 10.7653L14.7689 1.396H19.9688L18.7669 10.7653H16.2824Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M17.7758 40.4224C19.8308 40.4224 21.2258 39.1774 21.2258 37.5724C21.2258 36.0724 20.3258 35.2324 19.3658 34.6774V34.6024C20.0258 34.0924 20.8358 33.1174 20.8358 31.9474C20.8358 30.2674 19.6958 29.0674 17.8208 29.0674C16.0958 29.0674 14.7908 30.1774 14.7908 31.8424C14.7908 32.9974 15.4808 33.8374 16.2908 34.3924V34.4524C15.2858 34.9924 14.2658 36.0124 14.2658 37.4974C14.2658 39.1924 15.7508 40.4224 17.7758 40.4224ZM18.5258 34.2574C17.2358 33.7474 16.0508 33.1624 16.0508 31.8424C16.0508 30.7924 16.7858 30.0724 17.7908 30.0724C18.9758 30.0724 19.6658 30.9424 19.6658 32.0224C19.6658 32.8474 19.2758 33.5974 18.5258 34.2574ZM17.8058 39.3874C16.4858 39.3874 15.4958 38.5324 15.4958 37.3624C15.4958 36.3124 16.1108 35.4424 17.0108 34.8724C18.5558 35.5024 19.9058 36.0574 19.9058 37.5274C19.9058 38.6224 19.0658 39.3874 17.8058 39.3874Z" fill="#DADADA"/>
            </svg>
            : item == 9 ?
            <svg width="34" height="55" viewBox="0 0 34 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.2838 15.3947C33.2838 16.2293 33.1693 16.8002 32.9884 17.1835C32.816 17.5487 32.5794 17.7544 32.2777 17.8697C31.9561 17.9927 31.524 18.0263 30.9547 17.9624C30.3888 17.899 29.7288 17.7444 28.9734 17.529C28.3568 17.3532 27.6825 17.1376 26.9586 16.9062C24.1779 16.0172 20.6666 14.8947 16.8919 14.8947C13.1172 14.8947 9.60588 16.0172 6.82516 16.9062C6.10132 17.1376 5.42699 17.3532 4.8104 17.529C4.05497 17.7444 3.39496 17.899 2.82913 17.9624C2.25976 18.0263 1.82765 17.9927 1.50605 17.8697C1.20438 17.7544 0.96774 17.5487 0.795345 17.1835C0.614444 16.8002 0.5 16.2293 0.5 15.3947C0.5 12.1012 2.28469 9.08077 5.24689 6.86645C8.20918 4.65206 12.3247 3.26758 16.8919 3.26758C21.4591 3.26758 25.5746 4.65206 28.5369 6.86645C31.4991 9.08077 33.2838 12.1012 33.2838 15.3947Z" fill="white" stroke="black"/>
            <path d="M30.6079 18.2336C30.6079 19.9575 30.2083 20.5285 29.7895 20.7199C29.5525 20.8282 29.2223 20.8635 28.7608 20.8016C28.3015 20.7401 27.7597 20.5892 27.1299 20.3746C26.6219 20.2015 26.0635 19.9881 25.4621 19.7583C23.1351 18.869 20.164 17.7336 16.9756 17.7336C13.7872 17.7336 10.8161 18.869 8.48908 19.7583C7.88766 19.9881 7.32926 20.2015 6.82126 20.3746C6.19152 20.5892 5.64965 20.7401 5.19042 20.8016C4.72885 20.8635 4.39866 20.8282 4.16166 20.7199C3.74286 20.5285 3.34326 19.9575 3.34326 18.2336C3.34326 11.59 9.39206 6.11084 16.9756 6.11084C24.5591 6.11084 30.6079 11.59 30.6079 18.2336Z" fill="white" stroke="black"/>
            <rect x="0.5" y="15.4766" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.17578" y="18.4868" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.3667" y1="33.751" x2="30.9409" y2="33.751" stroke="black"/>
            <line x1="16.4331" y1="29.6948" x2="16.4331" y2="17.9866" stroke="black"/>
            <line x1="16.5591" y1="51.269" x2="16.5591" y2="38.7256" stroke="black"/>
            <line x1="2.67578" y1="33.877" x2="12.3761" y2="33.877" stroke="black"/>
            <rect x="12.9161" y="34.2573" width="5.68986" height="5.68986" transform="rotate(-45 12.9161 34.2573)" fill="white" stroke="black"/>
            <path d="M15.8151 10.1281L14.3016 0.758789H19.5015L18.2996 10.1281H15.8151Z" fill="white" stroke="black"/>
            <rect width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M16.1008 39.4224C18.1708 39.4224 20.0908 37.7124 20.0908 33.2424C20.0908 29.7474 18.5158 28.0224 16.4008 28.0224C14.6908 28.0224 13.2508 29.4624 13.2508 31.5924C13.2508 33.8724 14.4358 35.0424 16.2808 35.0424C17.2258 35.0424 18.2008 34.4724 18.8908 33.6024L18.8158 32.5074C18.0958 33.6024 17.2408 34.0374 16.5058 34.0374C15.2008 34.0374 14.5408 33.0624 14.5408 31.5924C14.5408 30.0774 15.3508 29.0874 16.4008 29.0874C17.9458 29.0874 18.8008 30.5424 18.8008 33.2424C18.8008 37.0074 17.5258 38.2824 16.0558 38.2824C15.3508 38.2824 14.6758 37.9674 14.2108 37.4274L13.4458 38.2974C14.0608 38.9424 14.9008 39.4224 16.1008 39.4224Z" fill="#DADADA"/>
            </svg>
            : item == 10 ?
            <svg width="35" height="55" viewBox="0 0 35 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.6505 15.3947C33.6505 16.2293 33.536 16.8002 33.3551 17.1835C33.1827 17.5487 32.9461 17.7544 32.6444 17.8697C32.3228 17.9927 31.8907 18.0263 31.3214 17.9624C30.7555 17.899 30.0955 17.7444 29.3401 17.529C28.7235 17.3532 28.0492 17.1376 27.3253 16.9062C24.5446 16.0172 21.0333 14.8947 17.2586 14.8947C13.4839 14.8947 9.97258 16.0172 7.19186 16.9062C6.46802 17.1376 5.79369 17.3532 5.1771 17.529C4.42167 17.7444 3.76166 17.899 3.19583 17.9624C2.62646 18.0263 2.19435 17.9927 1.87275 17.8697C1.57108 17.7544 1.33444 17.5487 1.16204 17.1835C0.981143 16.8002 0.866699 16.2293 0.866699 15.3947C0.866699 12.1012 2.65138 9.08077 5.61358 6.86645C8.57588 4.65206 12.6914 3.26758 17.2586 3.26758C21.8258 3.26758 25.9413 4.65206 28.9036 6.86645C31.8658 9.08077 33.6505 12.1012 33.6505 15.3947Z" fill="white" stroke="black"/>
            <path d="M30.9746 18.2336C30.9746 19.9575 30.575 20.5285 30.1562 20.7199C29.9192 20.8282 29.589 20.8635 29.1275 20.8016C28.6682 20.7401 28.1264 20.5892 27.4966 20.3746C26.9886 20.2015 26.4302 19.9881 25.8288 19.7583C23.5018 18.869 20.5307 17.7336 17.3423 17.7336C14.1539 17.7336 11.1828 18.869 8.85578 19.7583C8.25436 19.9881 7.69596 20.2015 7.18796 20.3746C6.55822 20.5892 6.01635 20.7401 5.55712 20.8016C5.09555 20.8635 4.76536 20.8282 4.52836 20.7199C4.10956 20.5285 3.70996 19.9575 3.70996 18.2336C3.70996 11.59 9.75875 6.11084 17.3423 6.11084C24.9258 6.11084 30.9746 11.59 30.9746 18.2336Z" fill="white" stroke="black"/>
            <rect x="0.866699" y="15.4766" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.54248" y="18.4868" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.7334" y1="33.751" x2="31.3076" y2="33.751" stroke="black"/>
            <line x1="16.7998" y1="29.6948" x2="16.7998" y2="17.9866" stroke="black"/>
            <line x1="16.9258" y1="51.269" x2="16.9258" y2="38.7256" stroke="black"/>
            <line x1="3.04248" y1="33.877" x2="12.7428" y2="33.877" stroke="black"/>
            <rect x="13.2828" y="34.2573" width="5.68986" height="5.68986" transform="rotate(-45 13.2828 34.2573)" fill="white" stroke="black"/>
            <path d="M16.1818 10.1281L14.6683 0.758789H19.8682L18.6663 10.1281H16.1818Z" fill="white" stroke="black"/>
            <rect width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M8.91082 39.2124H14.9408V38.0724H12.7208V28.2174H11.6708C11.0858 28.5774 10.3808 28.8324 9.39082 28.9974V29.8824H11.3558V38.0724H8.91082V39.2124ZM20.0811 39.4224C22.1511 39.4224 23.4861 37.5174 23.4861 33.6774C23.4861 29.8674 22.1511 28.0224 20.0811 28.0224C17.9811 28.0224 16.6461 29.8674 16.6461 33.6774C16.6461 37.5174 17.9811 39.4224 20.0811 39.4224ZM20.0811 38.3124C18.8211 38.3124 17.9661 36.9174 17.9661 33.6774C17.9661 30.4824 18.8211 29.1174 20.0811 29.1174C21.3261 29.1174 22.1661 30.4824 22.1661 33.6774C22.1661 36.9174 21.3261 38.3124 20.0811 38.3124Z" fill="#DADADA"/>
            </svg>
            : item == 11 ?
            <svg width="35" height="55" viewBox="0 0 35 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.0177 15.3947C34.0177 16.2293 33.9032 16.8002 33.7223 17.1835C33.5499 17.5487 33.3133 17.7544 33.0116 17.8697C32.69 17.9927 32.2579 18.0263 31.6885 17.9624C31.1227 17.899 30.4627 17.7444 29.7073 17.529C29.0907 17.3532 28.4163 17.1376 27.6925 16.9062C24.9118 16.0172 21.4005 14.8947 17.6258 14.8947C13.8511 14.8947 10.3398 16.0172 7.55905 16.9062C6.83521 17.1376 6.16088 17.3532 5.54429 17.529C4.78885 17.7444 4.12885 17.899 3.56302 17.9624C2.99365 18.0263 2.56153 17.9927 2.23994 17.8697C1.93827 17.7544 1.70163 17.5487 1.52923 17.1835C1.34833 16.8002 1.23389 16.2293 1.23389 15.3947C1.23389 12.1012 3.01857 9.08077 5.98077 6.86645C8.94307 4.65206 13.0585 3.26758 17.6258 3.26758C22.193 3.26758 26.3085 4.65206 29.2708 6.86645C32.233 9.08077 34.0177 12.1012 34.0177 15.3947Z" fill="white" stroke="black"/>
            <path d="M31.3418 18.2336C31.3418 19.9575 30.9422 20.5285 30.5234 20.7199C30.2864 20.8282 29.9562 20.8635 29.4946 20.8016C29.0354 20.7401 28.4935 20.5892 27.8638 20.3746C27.3558 20.2015 26.7974 19.9881 26.196 19.7583C23.869 18.869 20.8978 17.7336 17.7095 17.7336C14.5211 17.7336 11.55 18.869 9.22297 19.7583C8.62154 19.9881 8.06315 20.2015 7.55515 20.3746C6.92541 20.5892 6.38353 20.7401 5.92431 20.8016C5.46273 20.8635 5.13255 20.8282 4.89555 20.7199C4.47675 20.5285 4.07715 19.9575 4.07715 18.2336C4.07715 11.59 10.1259 6.11084 17.7095 6.11084C25.293 6.11084 31.3418 11.59 31.3418 18.2336Z" fill="white" stroke="black"/>
            <rect x="1.23389" y="15.4766" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.90967" y="18.4868" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="22.1006" y1="33.751" x2="31.6748" y2="33.751" stroke="black"/>
            <line x1="17.167" y1="29.6948" x2="17.167" y2="17.9866" stroke="black"/>
            <line x1="17.293" y1="51.269" x2="17.293" y2="38.7256" stroke="black"/>
            <line x1="3.40967" y1="33.877" x2="13.11" y2="33.877" stroke="black"/>
            <rect x="13.65" y="34.2573" width="5.68986" height="5.68986" transform="rotate(-45 13.65 34.2573)" fill="white" stroke="black"/>
            <path d="M16.549 10.1281L15.0355 0.758789H20.2354L19.0335 10.1281H16.549Z" fill="white" stroke="black"/>
            <rect x="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M10.9108 39.2124H16.9408V38.0724H14.7208V28.2174H13.6708C13.0858 28.5774 12.3808 28.8324 11.3908 28.9974V29.8824H13.3558V38.0724H10.9108V39.2124ZM19.2311 39.2124H25.2611V38.0724H23.0411V28.2174H21.9911C21.4061 28.5774 20.7011 28.8324 19.7111 28.9974V29.8824H21.6761V38.0724H19.2311V39.2124Z" fill="#DADADA"/>
            </svg>
            : item == 12 ?
            <svg width="34" height="55" viewBox="0 0 34 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.3844 15.3947C33.3844 16.2293 33.2699 16.8002 33.089 17.1835C32.9166 17.5487 32.68 17.7544 32.3783 17.8697C32.0567 17.9927 31.6246 18.0263 31.0552 17.9624C30.4894 17.899 29.8294 17.7444 29.074 17.529C28.4574 17.3532 27.783 17.1376 27.0592 16.9062C24.2785 16.0172 20.7672 14.8947 16.9925 14.8947C13.2178 14.8947 9.70647 16.0172 6.92575 16.9062C6.20191 17.1376 5.52757 17.3532 4.91099 17.529C4.15555 17.7444 3.49555 17.899 2.92972 17.9624C2.36035 18.0263 1.92823 17.9927 1.60664 17.8697C1.30497 17.7544 1.06833 17.5487 0.895931 17.1835C0.715029 16.8002 0.600586 16.2293 0.600586 15.3947C0.600586 12.1012 2.38527 9.08077 5.34747 6.86645C8.30977 4.65206 12.4252 3.26758 16.9925 3.26758C21.5597 3.26758 25.6752 4.65206 28.6375 6.86645C31.5997 9.08077 33.3844 12.1012 33.3844 15.3947Z" fill="white" stroke="black"/>
            <path d="M30.7085 18.2336C30.7085 19.9575 30.3089 20.5285 29.8901 20.7199C29.6531 20.8282 29.3229 20.8635 28.8613 20.8016C28.4021 20.7401 27.8602 20.5892 27.2305 20.3746C26.7225 20.2015 26.1641 19.9881 25.5627 19.7583C23.2357 18.869 20.2645 17.7336 17.0762 17.7336C13.8878 17.7336 10.9167 18.869 8.58967 19.7583C7.98824 19.9881 7.42985 20.2015 6.92185 20.3746C6.29211 20.5892 5.75023 20.7401 5.291 20.8016C4.82943 20.8635 4.49925 20.8282 4.26224 20.7199C3.84345 20.5285 3.44385 19.9575 3.44385 18.2336C3.44385 11.59 9.49264 6.11084 17.0762 6.11084C24.6597 6.11084 30.7085 11.59 30.7085 18.2336Z" fill="white" stroke="black"/>
            <rect x="0.600586" y="15.4766" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.27637" y="18.4868" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.4673" y1="33.751" x2="31.0415" y2="33.751" stroke="black"/>
            <line x1="16.5337" y1="29.6948" x2="16.5337" y2="17.9866" stroke="black"/>
            <line x1="16.6597" y1="51.269" x2="16.6597" y2="38.7256" stroke="black"/>
            <line x1="2.77637" y1="33.877" x2="12.4767" y2="33.877" stroke="black"/>
            <rect x="13.0167" y="34.2573" width="5.68986" height="5.68986" transform="rotate(-45 13.0167 34.2573)" fill="white" stroke="black"/>
            <path d="M15.9157 10.1281L14.4022 0.758789H19.6021L18.4002 10.1281H15.9157Z" fill="white" stroke="black"/>
            <rect width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M9.91082 39.2124H15.9408V38.0724H13.7208V28.2174H12.6708C12.0858 28.5774 11.3808 28.8324 10.3908 28.9974V29.8824H12.3558V38.0724H9.91082V39.2124ZM17.5711 39.2124H24.4711V38.0424H21.4261C20.8711 38.0424 20.2111 38.0874 19.6261 38.1474C22.2211 35.7024 23.9461 33.4674 23.9461 31.2624C23.9461 29.2974 22.7161 28.0224 20.7511 28.0224C19.3561 28.0224 18.3961 28.6674 17.4961 29.6424L18.2911 30.4074C18.9061 29.6874 19.6861 29.1324 20.5861 29.1324C21.9511 29.1324 22.5961 30.0624 22.5961 31.3224C22.5961 33.1974 21.0211 35.4024 17.5711 38.4174V39.2124Z" fill="#DADADA"/>
            </svg>
            : item == 13 ?
            <svg width="35" height="55" viewBox="0 0 35 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.7511 15.3947C33.7511 16.2293 33.6366 16.8002 33.4557 17.1835C33.2833 17.5487 33.0467 17.7544 32.745 17.8697C32.4234 17.9927 31.9913 18.0263 31.4219 17.9624C30.8561 17.899 30.1961 17.7444 29.4407 17.529C28.8241 17.3532 28.1497 17.1376 27.4259 16.9062C24.6452 16.0172 21.1339 14.8947 17.3592 14.8947C13.5845 14.8947 10.0732 16.0172 7.29245 16.9062C6.56861 17.1376 5.89427 17.3532 5.27769 17.529C4.52225 17.7444 3.86224 17.899 3.29642 17.9624C2.72705 18.0263 2.29493 17.9927 1.97334 17.8697C1.67167 17.7544 1.43503 17.5487 1.26263 17.1835C1.08173 16.8002 0.967285 16.2293 0.967285 15.3947C0.967285 12.1012 2.75197 9.08077 5.71417 6.86645C8.67647 4.65206 12.7919 3.26758 17.3592 3.26758C21.9264 3.26758 26.0419 4.65206 29.0042 6.86645C31.9664 9.08077 33.7511 12.1012 33.7511 15.3947Z" fill="white" stroke="black"/>
            <path d="M31.0752 18.2336C31.0752 19.9575 30.6756 20.5285 30.2568 20.7199C30.0198 20.8282 29.6896 20.8635 29.228 20.8016C28.7688 20.7401 28.2269 20.5892 27.5972 20.3746C27.0892 20.2015 26.5308 19.9881 25.9294 19.7583C23.6024 18.869 20.6312 17.7336 17.4429 17.7336C14.2545 17.7336 11.2834 18.869 8.95637 19.7583C8.35494 19.9881 7.79655 20.2015 7.28854 20.3746C6.65881 20.5892 6.11693 20.7401 5.6577 20.8016C5.19613 20.8635 4.86595 20.8282 4.62894 20.7199C4.21015 20.5285 3.81055 19.9575 3.81055 18.2336C3.81055 11.59 9.85934 6.11084 17.4429 6.11084C25.0264 6.11084 31.0752 11.59 31.0752 18.2336Z" fill="white" stroke="black"/>
            <rect x="0.967285" y="15.4766" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.64307" y="18.4868" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.834" y1="33.751" x2="31.4082" y2="33.751" stroke="black"/>
            <line x1="16.9004" y1="29.6948" x2="16.9004" y2="17.9866" stroke="black"/>
            <line x1="17.0264" y1="51.269" x2="17.0264" y2="38.7256" stroke="black"/>
            <line x1="3.14307" y1="33.877" x2="12.8434" y2="33.877" stroke="black"/>
            <rect x="13.3834" y="34.2573" width="5.68986" height="5.68986" transform="rotate(-45 13.3834 34.2573)" fill="white" stroke="black"/>
            <path d="M16.2824 10.1281L14.7689 0.758789H19.9688L18.7669 10.1281H16.2824Z" fill="white" stroke="black"/>
            <rect width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M9.91082 39.2124H15.9408V38.0724H13.7208V28.2174H12.6708C12.0858 28.5774 11.3808 28.8324 10.3908 28.9974V29.8824H12.3558V38.0724H9.91082V39.2124ZM20.8561 39.4224C22.8211 39.4224 24.3961 38.2374 24.3961 36.2874C24.3961 34.7724 23.3461 33.7974 22.0561 33.4824V33.4224C23.2261 33.0024 24.0061 32.1174 24.0061 30.7824C24.0061 29.0274 22.6561 28.0224 20.8111 28.0224C19.5511 28.0224 18.5761 28.5774 17.7511 29.3274L18.4861 30.2124C19.1011 29.5824 19.8811 29.1324 20.7661 29.1324C21.9061 29.1324 22.6111 29.8224 22.6111 30.8724C22.6111 32.0724 21.8461 32.9724 19.5811 32.9724V34.0374C22.1161 34.0374 23.0011 34.9074 23.0011 36.2274C23.0011 37.4874 22.0711 38.2824 20.7661 38.2824C19.5061 38.2824 18.6811 37.6824 18.0361 37.0074L17.3461 37.9074C18.0661 38.7024 19.1311 39.4224 20.8561 39.4224Z" fill="#DADADA"/>
            </svg>
            : item == 14 ?
            <svg width="34" height="55" viewBox="0 0 34 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.2838 15.7575C33.2838 16.5921 33.1693 17.163 32.9884 17.5463C32.816 17.9115 32.5794 18.1172 32.2777 18.2325C31.9561 18.3555 31.524 18.3891 30.9547 18.3252C30.3888 18.2618 29.7288 18.1072 28.9734 17.8918C28.3568 17.716 27.6825 17.5004 26.9586 17.269C24.1779 16.38 20.6666 15.2575 16.8919 15.2575C13.1172 15.2575 9.60588 16.38 6.82516 17.269C6.10132 17.5004 5.42699 17.716 4.8104 17.8918C4.05497 18.1072 3.39496 18.2618 2.82913 18.3252C2.25976 18.3891 1.82765 18.3555 1.50605 18.2325C1.20438 18.1172 0.96774 17.9115 0.795345 17.5463C0.614444 17.163 0.5 16.5921 0.5 15.7575C0.5 12.464 2.28469 9.44356 5.24689 7.22924C8.20918 5.01485 12.3247 3.63037 16.8919 3.63037C21.4591 3.63037 25.5746 5.01485 28.5369 7.22924C31.4991 9.44356 33.2838 12.464 33.2838 15.7575Z" fill="white" stroke="black"/>
            <path d="M30.6079 18.5964C30.6079 20.3203 30.2083 20.8913 29.7895 21.0827C29.5525 21.191 29.2223 21.2263 28.7608 21.1644C28.3015 21.1029 27.7597 20.952 27.1299 20.7374C26.6219 20.5643 26.0635 20.3509 25.4621 20.1211C23.1351 19.2318 20.164 18.0964 16.9756 18.0964C13.7872 18.0964 10.8161 19.2318 8.48908 20.1211C7.88766 20.3509 7.32926 20.5643 6.82126 20.7374C6.19152 20.952 5.64965 21.1029 5.19042 21.1644C4.72885 21.2263 4.39866 21.191 4.16166 21.0827C3.74286 20.8913 3.34326 20.3203 3.34326 18.5964C3.34326 11.9528 9.39206 6.47363 16.9756 6.47363C24.5591 6.47363 30.6079 11.9528 30.6079 18.5964Z" fill="white" stroke="black"/>
            <rect x="0.5" y="15.8394" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.17578" y="18.8496" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.3667" y1="34.1138" x2="30.9409" y2="34.1138" stroke="black"/>
            <line x1="16.4331" y1="30.0576" x2="16.4331" y2="18.3494" stroke="black"/>
            <line x1="16.5591" y1="51.6318" x2="16.5591" y2="39.0884" stroke="black"/>
            <line x1="2.67578" y1="34.2397" x2="12.3761" y2="34.2397" stroke="black"/>
            <rect x="12.9161" y="34.6201" width="5.68986" height="5.68986" transform="rotate(-45 12.9161 34.6201)" fill="white" stroke="black"/>
            <path d="M15.8151 10.4909L14.3016 1.12158H19.5015L18.2996 10.4909H15.8151Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M9.91082 40.2124H15.9408V39.0724H13.7208V29.2174H12.6708C12.0858 29.5774 11.3808 29.8324 10.3908 29.9974V30.8824H12.3558V39.0724H9.91082V40.2124ZM21.9961 40.2124H23.2861V29.2174H21.7711L17.1961 36.2824V37.1824H24.7561V36.1024H18.6361L21.1411 32.3524C21.4411 31.8124 21.7411 31.2574 22.0111 30.7174H22.0861C22.0411 31.2874 21.9961 32.1724 21.9961 32.7274V40.2124Z" fill="#DADADA"/>
            </svg>            
            : item == 15 ?
            <svg width="35" height="55" viewBox="0 0 35 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.6505 15.7575C33.6505 16.5921 33.536 17.163 33.3551 17.5463C33.1827 17.9115 32.9461 18.1172 32.6444 18.2325C32.3228 18.3555 31.8907 18.3891 31.3214 18.3252C30.7555 18.2618 30.0955 18.1072 29.3401 17.8918C28.7235 17.716 28.0492 17.5004 27.3253 17.269C24.5446 16.38 21.0333 15.2575 17.2586 15.2575C13.4839 15.2575 9.97258 16.38 7.19186 17.269C6.46802 17.5004 5.79369 17.716 5.1771 17.8918C4.42167 18.1072 3.76166 18.2618 3.19583 18.3252C2.62646 18.3891 2.19435 18.3555 1.87275 18.2325C1.57108 18.1172 1.33444 17.9115 1.16204 17.5463C0.981143 17.163 0.866699 16.5921 0.866699 15.7575C0.866699 12.464 2.65138 9.44356 5.61358 7.22924C8.57588 5.01485 12.6914 3.63037 17.2586 3.63037C21.8258 3.63037 25.9413 5.01485 28.9036 7.22924C31.8658 9.44356 33.6505 12.464 33.6505 15.7575Z" fill="white" stroke="black"/>
            <path d="M30.9746 18.5964C30.9746 20.3203 30.575 20.8913 30.1562 21.0827C29.9192 21.191 29.589 21.2263 29.1275 21.1644C28.6682 21.1029 28.1264 20.952 27.4966 20.7374C26.9886 20.5643 26.4302 20.3509 25.8288 20.1211C23.5018 19.2318 20.5307 18.0964 17.3423 18.0964C14.1539 18.0964 11.1828 19.2318 8.85578 20.1211C8.25436 20.3509 7.69596 20.5643 7.18796 20.7374C6.55822 20.952 6.01635 21.1029 5.55712 21.1644C5.09555 21.2263 4.76536 21.191 4.52836 21.0827C4.10956 20.8913 3.70996 20.3203 3.70996 18.5964C3.70996 11.9528 9.75875 6.47363 17.3423 6.47363C24.9258 6.47363 30.9746 11.9528 30.9746 18.5964Z" fill="white" stroke="black"/>
            <rect x="0.866699" y="15.8394" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.54248" y="18.8496" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.7334" y1="34.1138" x2="31.3076" y2="34.1138" stroke="black"/>
            <line x1="16.7998" y1="30.0576" x2="16.7998" y2="18.3494" stroke="black"/>
            <line x1="16.9258" y1="51.6318" x2="16.9258" y2="39.0884" stroke="black"/>
            <line x1="3.04248" y1="34.2397" x2="12.7428" y2="34.2397" stroke="black"/>
            <rect x="13.2828" y="34.6201" width="5.68986" height="5.68986" transform="rotate(-45 13.2828 34.6201)" fill="white" stroke="black"/>
            <path d="M16.1818 10.4909L14.6683 1.12158H19.8682L18.6663 10.4909H16.1818Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M9.91082 40.2124H15.9408V39.0724H13.7208V29.2174H12.6708C12.0858 29.5774 11.3808 29.8324 10.3908 29.9974V30.8824H12.3558V39.0724H9.91082V40.2124ZM20.8261 40.4224C22.6711 40.4224 24.4261 39.0424 24.4261 36.6424C24.4261 34.2124 22.9411 33.1324 21.1111 33.1324C20.4511 33.1324 19.9561 33.2974 19.4611 33.5674L19.7461 30.3874H23.9011V29.2174H18.5461L18.2011 34.3624L18.9361 34.8274C19.5661 34.4074 20.0311 34.1674 20.7661 34.1674C22.1311 34.1674 23.0311 35.1124 23.0311 36.6874C23.0311 38.2774 21.9961 39.2824 20.6911 39.2824C19.4311 39.2824 18.6211 38.6974 18.0061 38.0524L17.3161 38.9524C18.0661 39.7024 19.1161 40.4224 20.8261 40.4224Z" fill="#DADADA"/>
            </svg>
            : item == 16 ?
            <svg width="35" height="55" viewBox="0 0 35 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.0177 15.7575C34.0177 16.5921 33.9032 17.163 33.7223 17.5463C33.5499 17.9115 33.3133 18.1172 33.0116 18.2325C32.69 18.3555 32.2579 18.3891 31.6885 18.3252C31.1227 18.2618 30.4627 18.1072 29.7073 17.8918C29.0907 17.716 28.4163 17.5004 27.6925 17.269C24.9118 16.38 21.4005 15.2575 17.6258 15.2575C13.8511 15.2575 10.3398 16.38 7.55905 17.269C6.83521 17.5004 6.16088 17.716 5.54429 17.8918C4.78885 18.1072 4.12885 18.2618 3.56302 18.3252C2.99365 18.3891 2.56153 18.3555 2.23994 18.2325C1.93827 18.1172 1.70163 17.9115 1.52923 17.5463C1.34833 17.163 1.23389 16.5921 1.23389 15.7575C1.23389 12.464 3.01857 9.44356 5.98077 7.22924C8.94307 5.01485 13.0585 3.63037 17.6258 3.63037C22.193 3.63037 26.3085 5.01485 29.2708 7.22924C32.233 9.44356 34.0177 12.464 34.0177 15.7575Z" fill="white" stroke="black"/>
            <path d="M31.3418 18.5964C31.3418 20.3203 30.9422 20.8913 30.5234 21.0827C30.2864 21.191 29.9562 21.2263 29.4946 21.1644C29.0354 21.1029 28.4935 20.952 27.8638 20.7374C27.3558 20.5643 26.7974 20.3509 26.196 20.1211C23.869 19.2318 20.8978 18.0964 17.7095 18.0964C14.5211 18.0964 11.55 19.2318 9.22297 20.1211C8.62154 20.3509 8.06315 20.5643 7.55515 20.7374C6.92541 20.952 6.38353 21.1029 5.92431 21.1644C5.46273 21.2263 5.13255 21.191 4.89555 21.0827C4.47675 20.8913 4.07715 20.3203 4.07715 18.5964C4.07715 11.9528 10.1259 6.47363 17.7095 6.47363C25.293 6.47363 31.3418 11.9528 31.3418 18.5964Z" fill="white" stroke="black"/>
            <rect x="1.23389" y="15.8394" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.90967" y="18.8496" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="22.1006" y1="34.1138" x2="31.6748" y2="34.1138" stroke="black"/>
            <line x1="17.167" y1="30.0576" x2="17.167" y2="18.3494" stroke="black"/>
            <line x1="17.293" y1="51.6318" x2="17.293" y2="39.0884" stroke="black"/>
            <line x1="3.40967" y1="34.2397" x2="13.11" y2="34.2397" stroke="black"/>
            <rect x="13.65" y="34.6201" width="5.68986" height="5.68986" transform="rotate(-45 13.65 34.6201)" fill="white" stroke="black"/>
            <path d="M16.549 10.4909L15.0355 1.12158H20.2354L19.0335 10.4909H16.549Z" fill="white" stroke="black"/>
            <rect x="1" y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M10.9108 40.2124H16.9408V39.0724H14.7208V29.2174H13.6708C13.0858 29.5774 12.3808 29.8324 11.3908 29.9974V30.8824H13.3558V39.0724H10.9108V40.2124ZM22.4261 40.4224C24.1211 40.4224 25.5761 38.9824 25.5761 36.8524C25.5761 34.5424 24.3761 33.4024 22.5161 33.4024C21.6311 33.4024 20.6111 33.9424 19.9361 34.8274L19.9811 35.9224C20.7161 34.8274 21.6011 34.4074 22.3061 34.4074C23.6261 34.4074 24.2861 35.3524 24.2861 36.8524C24.2861 38.3374 23.4761 39.3424 22.4261 39.3424C20.8811 39.3424 20.0261 37.7974 20.0261 34.9774C20.0261 31.3774 21.3161 30.1624 22.8611 30.1624C23.5211 30.1624 24.1961 30.4774 24.6161 30.9874L25.3811 30.1474C24.7811 29.5024 23.9411 29.0224 22.8011 29.0224C20.6711 29.0224 18.7361 30.6724 18.7361 34.9774C18.7361 38.6074 20.3111 40.4224 22.4261 40.4224Z" fill="#DADADA"/>
            </svg>
            : item == 17 ?
            <svg width="34" height="55" viewBox="0 0 34 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.3844 15.7575C33.3844 16.5921 33.2699 17.163 33.089 17.5463C32.9166 17.9115 32.68 18.1172 32.3783 18.2325C32.0567 18.3555 31.6246 18.3891 31.0552 18.3252C30.4894 18.2618 29.8294 18.1072 29.074 17.8918C28.4574 17.716 27.783 17.5004 27.0592 17.269C24.2785 16.38 20.7672 15.2575 16.9925 15.2575C13.2178 15.2575 9.70647 16.38 6.92575 17.269C6.20191 17.5004 5.52757 17.716 4.91099 17.8918C4.15555 18.1072 3.49555 18.2618 2.92972 18.3252C2.36035 18.3891 1.92823 18.3555 1.60664 18.2325C1.30497 18.1172 1.06833 17.9115 0.895931 17.5463C0.715029 17.163 0.600586 16.5921 0.600586 15.7575C0.600586 12.464 2.38527 9.44356 5.34747 7.22924C8.30977 5.01485 12.4252 3.63037 16.9925 3.63037C21.5597 3.63037 25.6752 5.01485 28.6375 7.22924C31.5997 9.44356 33.3844 12.464 33.3844 15.7575Z" fill="white" stroke="black"/>
            <path d="M30.7085 18.5964C30.7085 20.3203 30.3089 20.8913 29.8901 21.0827C29.6531 21.191 29.3229 21.2263 28.8613 21.1644C28.4021 21.1029 27.8602 20.952 27.2305 20.7374C26.7225 20.5643 26.1641 20.3509 25.5627 20.1211C23.2357 19.2318 20.2645 18.0964 17.0762 18.0964C13.8878 18.0964 10.9167 19.2318 8.58967 20.1211C7.98824 20.3509 7.42985 20.5643 6.92185 20.7374C6.29211 20.952 5.75023 21.1029 5.291 21.1644C4.82943 21.2263 4.49925 21.191 4.26224 21.0827C3.84345 20.8913 3.44385 20.3203 3.44385 18.5964C3.44385 11.9528 9.49264 6.47363 17.0762 6.47363C24.6597 6.47363 30.7085 11.9528 30.7085 18.5964Z" fill="white" stroke="black"/>
            <rect x="0.600586" y="15.8394" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.27637" y="18.8496" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.4673" y1="34.1138" x2="31.0415" y2="34.1138" stroke="black"/>
            <line x1="16.5337" y1="30.0576" x2="16.5337" y2="18.3494" stroke="black"/>
            <line x1="16.6597" y1="51.6318" x2="16.6597" y2="39.0884" stroke="black"/>
            <line x1="2.77637" y1="34.2397" x2="12.4767" y2="34.2397" stroke="black"/>
            <rect x="13.0167" y="34.6201" width="5.68986" height="5.68986" transform="rotate(-45 13.0167 34.6201)" fill="white" stroke="black"/>
            <path d="M15.9157 10.4909L14.4022 1.12158H19.6021L18.4002 10.4909H15.9157Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M9.91082 40.2124H15.9408V39.0724H13.7208V29.2174H12.6708C12.0858 29.5774 11.3808 29.8324 10.3908 29.9974V30.8824H12.3558V39.0724H9.91082V40.2124ZM19.8661 40.2124H21.2911C21.4711 35.9224 21.9361 33.3574 24.5161 30.0424V29.2174H17.6461V30.3874H22.9711C20.8111 33.3874 20.0611 36.0574 19.8661 40.2124Z" fill="#DADADA"/>
            </svg>            
            : item == 18 ?
            <svg width="35" height="55" viewBox="0 0 35 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.7511 15.7575C33.7511 16.5921 33.6366 17.163 33.4557 17.5463C33.2833 17.9115 33.0467 18.1172 32.745 18.2325C32.4234 18.3555 31.9913 18.3891 31.4219 18.3252C30.8561 18.2618 30.1961 18.1072 29.4407 17.8918C28.8241 17.716 28.1497 17.5004 27.4259 17.269C24.6452 16.38 21.1339 15.2575 17.3592 15.2575C13.5845 15.2575 10.0732 16.38 7.29245 17.269C6.56861 17.5004 5.89427 17.716 5.27769 17.8918C4.52225 18.1072 3.86224 18.2618 3.29642 18.3252C2.72705 18.3891 2.29493 18.3555 1.97334 18.2325C1.67167 18.1172 1.43503 17.9115 1.26263 17.5463C1.08173 17.163 0.967285 16.5921 0.967285 15.7575C0.967285 12.464 2.75197 9.44356 5.71417 7.22924C8.67647 5.01485 12.7919 3.63037 17.3592 3.63037C21.9264 3.63037 26.0419 5.01485 29.0042 7.22924C31.9664 9.44356 33.7511 12.464 33.7511 15.7575Z" fill="white" stroke="black"/>
            <path d="M31.0752 18.5964C31.0752 20.3203 30.6756 20.8913 30.2568 21.0827C30.0198 21.191 29.6896 21.2263 29.228 21.1644C28.7688 21.1029 28.2269 20.952 27.5972 20.7374C27.0892 20.5643 26.5308 20.3509 25.9294 20.1211C23.6024 19.2318 20.6312 18.0964 17.4429 18.0964C14.2545 18.0964 11.2834 19.2318 8.95637 20.1211C8.35494 20.3509 7.79655 20.5643 7.28854 20.7374C6.65881 20.952 6.11693 21.1029 5.6577 21.1644C5.19613 21.2263 4.86595 21.191 4.62894 21.0827C4.21015 20.8913 3.81055 20.3203 3.81055 18.5964C3.81055 11.9528 9.85934 6.47363 17.4429 6.47363C25.0264 6.47363 31.0752 11.9528 31.0752 18.5964Z" fill="white" stroke="black"/>
            <rect x="0.967285" y="15.8394" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.64307" y="18.8496" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.834" y1="34.1138" x2="31.4082" y2="34.1138" stroke="black"/>
            <line x1="16.9004" y1="30.0576" x2="16.9004" y2="18.3494" stroke="black"/>
            <line x1="17.0264" y1="51.6318" x2="17.0264" y2="39.0884" stroke="black"/>
            <line x1="3.14307" y1="34.2397" x2="12.8434" y2="34.2397" stroke="black"/>
            <rect x="13.3834" y="34.6201" width="5.68986" height="5.68986" transform="rotate(-45 13.3834 34.6201)" fill="white" stroke="black"/>
            <path d="M16.2824 10.4909L14.7689 1.12158H19.9688L18.7669 10.4909H16.2824Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M9.91082 40.2124H15.9408V39.0724H13.7208V29.2174H12.6708C12.0858 29.5774 11.3808 29.8324 10.3908 29.9974V30.8824H12.3558V39.0724H9.91082V40.2124ZM21.0961 40.4224C23.1511 40.4224 24.5461 39.1774 24.5461 37.5724C24.5461 36.0724 23.6461 35.2324 22.6861 34.6774V34.6024C23.3461 34.0924 24.1561 33.1174 24.1561 31.9474C24.1561 30.2674 23.0161 29.0674 21.1411 29.0674C19.4161 29.0674 18.1111 30.1774 18.1111 31.8424C18.1111 32.9974 18.8011 33.8374 19.6111 34.3924V34.4524C18.6061 34.9924 17.5861 36.0124 17.5861 37.4974C17.5861 39.1924 19.0711 40.4224 21.0961 40.4224ZM21.8461 34.2574C20.5561 33.7474 19.3711 33.1624 19.3711 31.8424C19.3711 30.7924 20.1061 30.0724 21.1111 30.0724C22.2961 30.0724 22.9861 30.9424 22.9861 32.0224C22.9861 32.8474 22.5961 33.5974 21.8461 34.2574ZM21.1261 39.3874C19.8061 39.3874 18.8161 38.5324 18.8161 37.3624C18.8161 36.3124 19.4311 35.4424 20.3311 34.8724C21.8761 35.5024 23.2261 36.0574 23.2261 37.5274C23.2261 38.6224 22.3861 39.3874 21.1261 39.3874Z" fill="#DADADA"/>
            </svg>
            : item == 19 ?
            <svg width="34" height="56" viewBox="0 0 34 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.2838 16.1203C33.2838 16.9549 33.1693 17.5258 32.9884 17.9091C32.816 18.2743 32.5794 18.4799 32.2777 18.5953C31.9561 18.7183 31.524 18.7519 30.9547 18.688C30.3888 18.6246 29.7288 18.47 28.9734 18.2546C28.3568 18.0788 27.6825 17.8632 26.9586 17.6318C24.1779 16.7428 20.6666 15.6203 16.8919 15.6203C13.1172 15.6203 9.60588 16.7428 6.82516 17.6318C6.10132 17.8632 5.42699 18.0788 4.8104 18.2546C4.05497 18.47 3.39496 18.6246 2.82913 18.688C2.25976 18.7519 1.82765 18.7183 1.50605 18.5953C1.20438 18.4799 0.96774 18.2743 0.795345 17.9091C0.614444 17.5258 0.5 16.9549 0.5 16.1203C0.5 12.8268 2.28469 9.80635 5.24689 7.59203C8.20918 5.37765 12.3247 3.99316 16.8919 3.99316C21.4591 3.99316 25.5746 5.37765 28.5369 7.59203C31.4991 9.80635 33.2838 12.8268 33.2838 16.1203Z" fill="white" stroke="black"/>
            <path d="M30.6079 18.9592C30.6079 20.6831 30.2083 21.2541 29.7895 21.4455C29.5525 21.5538 29.2223 21.5891 28.7608 21.5272C28.3015 21.4657 27.7597 21.3148 27.1299 21.1002C26.6219 20.9271 26.0635 20.7137 25.4621 20.4839C23.1351 19.5946 20.164 18.4592 16.9756 18.4592C13.7872 18.4592 10.8161 19.5946 8.48908 20.4839C7.88766 20.7137 7.32926 20.9271 6.82126 21.1002C6.19152 21.3148 5.64965 21.4657 5.19042 21.5272C4.72885 21.5891 4.39866 21.5538 4.16166 21.4455C3.74286 21.2541 3.34326 20.6831 3.34326 18.9592C3.34326 12.3156 9.39206 6.83643 16.9756 6.83643C24.5591 6.83643 30.6079 12.3156 30.6079 18.9592Z" fill="white" stroke="black"/>
            <rect x="0.5" y="16.2021" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.17578" y="19.2124" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.3667" y1="34.4766" x2="30.9409" y2="34.4766" stroke="black"/>
            <line x1="16.4331" y1="30.4204" x2="16.4331" y2="18.7122" stroke="black"/>
            <line x1="16.5591" y1="51.9946" x2="16.5591" y2="39.4511" stroke="black"/>
            <line x1="2.67578" y1="34.6025" x2="12.3761" y2="34.6025" stroke="black"/>
            <rect x="12.9161" y="34.9829" width="5.68986" height="5.68986" transform="rotate(-45 12.9161 34.9829)" fill="white" stroke="black"/>
            <path d="M15.8151 10.8537L14.3016 1.48438H19.5015L18.2996 10.8537H15.8151Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M9.91082 40.2124H15.9408V39.0724H13.7208V29.2174H12.6708C12.0858 29.5774 11.3808 29.8324 10.3908 29.9974V30.8824H12.3558V39.0724H9.91082V40.2124ZM20.4211 40.4224C22.4911 40.4224 24.4111 38.7124 24.4111 34.2424C24.4111 30.7474 22.8361 29.0224 20.7211 29.0224C19.0111 29.0224 17.5711 30.4624 17.5711 32.5924C17.5711 34.8724 18.7561 36.0424 20.6011 36.0424C21.5461 36.0424 22.5211 35.4724 23.2111 34.6024L23.1361 33.5074C22.4161 34.6024 21.5611 35.0374 20.8261 35.0374C19.5211 35.0374 18.8611 34.0624 18.8611 32.5924C18.8611 31.0774 19.6711 30.0874 20.7211 30.0874C22.2661 30.0874 23.1211 31.5424 23.1211 34.2424C23.1211 38.0074 21.8461 39.2824 20.3761 39.2824C19.6711 39.2824 18.9961 38.9674 18.5311 38.4274L17.7661 39.2974C18.3811 39.9424 19.2211 40.4224 20.4211 40.4224Z" fill="#DADADA"/>
            </svg>
            : item == 20 ?
            <svg width="35" height="56" viewBox="0 0 35 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.6505 16.1203C33.6505 16.9549 33.536 17.5258 33.3551 17.9091C33.1827 18.2743 32.9461 18.4799 32.6444 18.5953C32.3228 18.7183 31.8907 18.7519 31.3214 18.688C30.7555 18.6246 30.0955 18.47 29.3401 18.2546C28.7235 18.0788 28.0492 17.8632 27.3253 17.6318C24.5446 16.7428 21.0333 15.6203 17.2586 15.6203C13.4839 15.6203 9.97258 16.7428 7.19186 17.6318C6.46802 17.8632 5.79369 18.0788 5.1771 18.2546C4.42167 18.47 3.76166 18.6246 3.19583 18.688C2.62646 18.7519 2.19435 18.7183 1.87275 18.5953C1.57108 18.4799 1.33444 18.2743 1.16204 17.9091C0.981143 17.5258 0.866699 16.9549 0.866699 16.1203C0.866699 12.8268 2.65138 9.80635 5.61358 7.59203C8.57588 5.37765 12.6914 3.99316 17.2586 3.99316C21.8258 3.99316 25.9413 5.37765 28.9036 7.59203C31.8658 9.80635 33.6505 12.8268 33.6505 16.1203Z" fill="white" stroke="black"/>
            <path d="M30.9746 18.9592C30.9746 20.6831 30.575 21.2541 30.1562 21.4455C29.9192 21.5538 29.589 21.5891 29.1275 21.5272C28.6682 21.4657 28.1264 21.3148 27.4966 21.1002C26.9886 20.9271 26.4302 20.7137 25.8288 20.4839C23.5018 19.5946 20.5307 18.4592 17.3423 18.4592C14.1539 18.4592 11.1828 19.5946 8.85578 20.4839C8.25436 20.7137 7.69596 20.9271 7.18796 21.1002C6.55822 21.3148 6.01635 21.4657 5.55712 21.5272C5.09555 21.5891 4.76536 21.5538 4.52836 21.4455C4.10956 21.2541 3.70996 20.6831 3.70996 18.9592C3.70996 12.3156 9.75875 6.83643 17.3423 6.83643C24.9258 6.83643 30.9746 12.3156 30.9746 18.9592Z" fill="white" stroke="black"/>
            <rect x="0.866699" y="16.2021" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.54248" y="19.2124" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.7334" y1="34.4766" x2="31.3076" y2="34.4766" stroke="black"/>
            <line x1="16.7998" y1="30.4204" x2="16.7998" y2="18.7122" stroke="black"/>
            <line x1="16.9258" y1="51.9946" x2="16.9258" y2="39.4511" stroke="black"/>
            <line x1="3.04248" y1="34.6025" x2="12.7428" y2="34.6025" stroke="black"/>
            <rect x="13.2828" y="34.9829" width="5.68986" height="5.68986" transform="rotate(-45 13.2828 34.9829)" fill="white" stroke="black"/>
            <path d="M16.1818 10.8537L14.6683 1.48438H19.8682L18.6663 10.8537H16.1818Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M9.25082 40.2124H16.1508V39.0424H13.1058C12.5508 39.0424 11.8908 39.0874 11.3058 39.1474C13.9008 36.7024 15.6258 34.4674 15.6258 32.2624C15.6258 30.2974 14.3958 29.0224 12.4308 29.0224C11.0358 29.0224 10.0758 29.6674 9.17582 30.6424L9.97082 31.4074C10.5858 30.6874 11.3658 30.1324 12.2658 30.1324C13.6308 30.1324 14.2758 31.0624 14.2758 32.3224C14.2758 34.1974 12.7008 36.4024 9.25082 39.4174V40.2124ZM21.0811 40.4224C23.1511 40.4224 24.4861 38.5174 24.4861 34.6774C24.4861 30.8674 23.1511 29.0224 21.0811 29.0224C18.9811 29.0224 17.6461 30.8674 17.6461 34.6774C17.6461 38.5174 18.9811 40.4224 21.0811 40.4224ZM21.0811 39.3124C19.8211 39.3124 18.9661 37.9174 18.9661 34.6774C18.9661 31.4824 19.8211 30.1174 21.0811 30.1174C22.3261 30.1174 23.1661 31.4824 23.1661 34.6774C23.1661 37.9174 22.3261 39.3124 21.0811 39.3124Z" fill="#DADADA"/>
            </svg>
            : item == 21 ?
            <svg width="35" height="56" viewBox="0 0 35 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.0177 16.1203C34.0177 16.9549 33.9032 17.5258 33.7223 17.9091C33.5499 18.2743 33.3133 18.4799 33.0116 18.5953C32.69 18.7183 32.2579 18.7519 31.6885 18.688C31.1227 18.6246 30.4627 18.47 29.7073 18.2546C29.0907 18.0788 28.4163 17.8632 27.6925 17.6318C24.9118 16.7428 21.4005 15.6203 17.6258 15.6203C13.8511 15.6203 10.3398 16.7428 7.55905 17.6318C6.83521 17.8632 6.16088 18.0788 5.54429 18.2546C4.78885 18.47 4.12885 18.6246 3.56302 18.688C2.99365 18.7519 2.56153 18.7183 2.23994 18.5953C1.93827 18.4799 1.70163 18.2743 1.52923 17.9091C1.34833 17.5258 1.23389 16.9549 1.23389 16.1203C1.23389 12.8268 3.01857 9.80635 5.98077 7.59203C8.94307 5.37765 13.0585 3.99316 17.6258 3.99316C22.193 3.99316 26.3085 5.37765 29.2708 7.59203C32.233 9.80635 34.0177 12.8268 34.0177 16.1203Z" fill="white" stroke="black"/>
            <path d="M31.3418 18.9592C31.3418 20.6831 30.9422 21.2541 30.5234 21.4455C30.2864 21.5538 29.9562 21.5891 29.4946 21.5272C29.0354 21.4657 28.4935 21.3148 27.8638 21.1002C27.3558 20.9271 26.7974 20.7137 26.196 20.4839C23.869 19.5946 20.8978 18.4592 17.7095 18.4592C14.5211 18.4592 11.55 19.5946 9.22297 20.4839C8.62154 20.7137 8.06315 20.9271 7.55515 21.1002C6.92541 21.3148 6.38353 21.4657 5.92431 21.5272C5.46273 21.5891 5.13255 21.5538 4.89555 21.4455C4.47675 21.2541 4.07715 20.6831 4.07715 18.9592C4.07715 12.3156 10.1259 6.83643 17.7095 6.83643C25.293 6.83643 31.3418 12.3156 31.3418 18.9592Z" fill="white" stroke="black"/>
            <rect x="1.23389" y="16.2021" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.90967" y="19.2124" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="22.1006" y1="34.4766" x2="31.6748" y2="34.4766" stroke="black"/>
            <line x1="17.167" y1="30.4204" x2="17.167" y2="18.7122" stroke="black"/>
            <line x1="17.293" y1="51.9946" x2="17.293" y2="39.4511" stroke="black"/>
            <line x1="3.40967" y1="34.6025" x2="13.11" y2="34.6025" stroke="black"/>
            <rect x="13.65" y="34.9829" width="5.68986" height="5.68986" transform="rotate(-45 13.65 34.9829)" fill="white" stroke="black"/>
            <path d="M16.549 10.8537L15.0355 1.48438H20.2354L19.0335 10.8537H16.549Z" fill="white" stroke="black"/>
            <rect x="1" y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M9.25082 40.2124H16.1508V39.0424H13.1058C12.5508 39.0424 11.8908 39.0874 11.3058 39.1474C13.9008 36.7024 15.6258 34.4674 15.6258 32.2624C15.6258 30.2974 14.3958 29.0224 12.4308 29.0224C11.0358 29.0224 10.0758 29.6674 9.17582 30.6424L9.97082 31.4074C10.5858 30.6874 11.3658 30.1324 12.2658 30.1324C13.6308 30.1324 14.2758 31.0624 14.2758 32.3224C14.2758 34.1974 12.7008 36.4024 9.25082 39.4174V40.2124ZM18.2311 40.2124H24.2611V39.0724H22.0411V29.2174H20.9911C20.4061 29.5774 19.7011 29.8324 18.7111 29.9974V30.8824H20.6761V39.0724H18.2311V40.2124Z" fill="#DADADA"/>
            </svg>            
            : item == 22 ?
            <svg width="34" height="56" viewBox="0 0 34 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.3844 16.1203C33.3844 16.9549 33.2699 17.5258 33.089 17.9091C32.9166 18.2743 32.68 18.4799 32.3783 18.5953C32.0567 18.7183 31.6246 18.7519 31.0552 18.688C30.4894 18.6246 29.8294 18.47 29.074 18.2546C28.4574 18.0788 27.783 17.8632 27.0592 17.6318C24.2785 16.7428 20.7672 15.6203 16.9925 15.6203C13.2178 15.6203 9.70647 16.7428 6.92575 17.6318C6.20191 17.8632 5.52757 18.0788 4.91099 18.2546C4.15555 18.47 3.49555 18.6246 2.92972 18.688C2.36035 18.7519 1.92823 18.7183 1.60664 18.5953C1.30497 18.4799 1.06833 18.2743 0.895931 17.9091C0.715029 17.5258 0.600586 16.9549 0.600586 16.1203C0.600586 12.8268 2.38527 9.80635 5.34747 7.59203C8.30977 5.37765 12.4252 3.99316 16.9925 3.99316C21.5597 3.99316 25.6752 5.37765 28.6375 7.59203C31.5997 9.80635 33.3844 12.8268 33.3844 16.1203Z" fill="white" stroke="black"/>
            <path d="M30.7085 18.9592C30.7085 20.6831 30.3089 21.2541 29.8901 21.4455C29.6531 21.5538 29.3229 21.5891 28.8613 21.5272C28.4021 21.4657 27.8602 21.3148 27.2305 21.1002C26.7225 20.9271 26.1641 20.7137 25.5627 20.4839C23.2357 19.5946 20.2645 18.4592 17.0762 18.4592C13.8878 18.4592 10.9167 19.5946 8.58967 20.4839C7.98824 20.7137 7.42985 20.9271 6.92185 21.1002C6.29211 21.3148 5.75023 21.4657 5.291 21.5272C4.82943 21.5891 4.49925 21.5538 4.26224 21.4455C3.84345 21.2541 3.44385 20.6831 3.44385 18.9592C3.44385 12.3156 9.49264 6.83643 17.0762 6.83643C24.6597 6.83643 30.7085 12.3156 30.7085 18.9592Z" fill="white" stroke="black"/>
            <rect x="0.600586" y="16.2021" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.27637" y="19.2124" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.4673" y1="34.4766" x2="31.0415" y2="34.4766" stroke="black"/>
            <line x1="16.5337" y1="30.4204" x2="16.5337" y2="18.7122" stroke="black"/>
            <line x1="16.6597" y1="51.9946" x2="16.6597" y2="39.4511" stroke="black"/>
            <line x1="2.77637" y1="34.6025" x2="12.4767" y2="34.6025" stroke="black"/>
            <rect x="13.0167" y="34.9829" width="5.68986" height="5.68986" transform="rotate(-45 13.0167 34.9829)" fill="white" stroke="black"/>
            <path d="M15.9157 10.8537L14.4022 1.48438H19.6021L18.4002 10.8537H15.9157Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M10.2508 40.2124H17.1508V39.0424H14.1058C13.5508 39.0424 12.8908 39.0874 12.3058 39.1474C14.9008 36.7024 16.6258 34.4674 16.6258 32.2624C16.6258 30.2974 15.3958 29.0224 13.4308 29.0224C12.0358 29.0224 11.0758 29.6674 10.1758 30.6424L10.9708 31.4074C11.5858 30.6874 12.3658 30.1324 13.2658 30.1324C14.6308 30.1324 15.2758 31.0624 15.2758 32.3224C15.2758 34.1974 13.7008 36.4024 10.2508 39.4174V40.2124ZM18.5711 40.2124H25.4711V39.0424H22.4261C21.8711 39.0424 21.2111 39.0874 20.6261 39.1474C23.2211 36.7024 24.9461 34.4674 24.9461 32.2624C24.9461 30.2974 23.7161 29.0224 21.7511 29.0224C20.3561 29.0224 19.3961 29.6674 18.4961 30.6424L19.2911 31.4074C19.9061 30.6874 20.6861 30.1324 21.5861 30.1324C22.9511 30.1324 23.5961 31.0624 23.5961 32.3224C23.5961 34.1974 22.0211 36.4024 18.5711 39.4174V40.2124Z" fill="#DADADA"/>
            </svg>            
            :
            <svg width="35" height="56" viewBox="0 0 35 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.7511 16.1203C33.7511 16.9549 33.6366 17.5258 33.4557 17.9091C33.2833 18.2743 33.0467 18.4799 32.745 18.5953C32.4234 18.7183 31.9913 18.7519 31.4219 18.688C30.8561 18.6246 30.1961 18.47 29.4407 18.2546C28.8241 18.0788 28.1497 17.8632 27.4259 17.6318C24.6452 16.7428 21.1339 15.6203 17.3592 15.6203C13.5845 15.6203 10.0732 16.7428 7.29245 17.6318C6.56861 17.8632 5.89427 18.0788 5.27769 18.2546C4.52225 18.47 3.86224 18.6246 3.29642 18.688C2.72705 18.7519 2.29493 18.7183 1.97334 18.5953C1.67167 18.4799 1.43503 18.2743 1.26263 17.9091C1.08173 17.5258 0.967285 16.9549 0.967285 16.1203C0.967285 12.8268 2.75197 9.80635 5.71417 7.59203C8.67647 5.37765 12.7919 3.99316 17.3592 3.99316C21.9264 3.99316 26.0419 5.37765 29.0042 7.59203C31.9664 9.80635 33.7511 12.8268 33.7511 16.1203Z" fill="white" stroke="black"/>
            <path d="M31.0752 18.9592C31.0752 20.6831 30.6756 21.2541 30.2568 21.4455C30.0198 21.5538 29.6896 21.5891 29.228 21.5272C28.7688 21.4657 28.2269 21.3148 27.5972 21.1002C27.0892 20.9271 26.5308 20.7137 25.9294 20.4839C23.6024 19.5946 20.6312 18.4592 17.4429 18.4592C14.2545 18.4592 11.2834 19.5946 8.95637 20.4839C8.35494 20.7137 7.79655 20.9271 7.28854 21.1002C6.65881 21.3148 6.11693 21.4657 5.6577 21.5272C5.19613 21.5891 4.86595 21.5538 4.62894 21.4455C4.21015 21.2541 3.81055 20.6831 3.81055 18.9592C3.81055 12.3156 9.85934 6.83643 17.4429 6.83643C25.0264 6.83643 31.0752 12.3156 31.0752 18.9592Z" fill="white" stroke="black"/>
            <rect x="0.967285" y="16.2021" width="32.7838" height="38.4702" fill="white" stroke="black"/>
            <rect x="3.64307" y="19.2124" width="27.4319" height="32.4493" fill="white" stroke="black"/>
            <line x1="21.834" y1="34.4766" x2="31.4082" y2="34.4766" stroke="black"/>
            <line x1="16.9004" y1="30.4204" x2="16.9004" y2="18.7122" stroke="black"/>
            <line x1="17.0264" y1="51.9946" x2="17.0264" y2="39.4511" stroke="black"/>
            <line x1="3.14307" y1="34.6025" x2="12.8434" y2="34.6025" stroke="black"/>
            <rect x="13.3834" y="34.9829" width="5.68986" height="5.68986" transform="rotate(-45 13.3834 34.9829)" fill="white" stroke="black"/>
            <path d="M16.2824 10.8537L14.7689 1.48438H19.9688L18.7669 10.8537H16.2824Z" fill="white" stroke="black"/>
            <rect y="1" width="34" height="54" fill="black" fill-opacity="0.3"/>
            <path d="M9.25082 40.2124H16.1508V39.0424H13.1058C12.5508 39.0424 11.8908 39.0874 11.3058 39.1474C13.9008 36.7024 15.6258 34.4674 15.6258 32.2624C15.6258 30.2974 14.3958 29.0224 12.4308 29.0224C11.0358 29.0224 10.0758 29.6674 9.17582 30.6424L9.97082 31.4074C10.5858 30.6874 11.3658 30.1324 12.2658 30.1324C13.6308 30.1324 14.2758 31.0624 14.2758 32.3224C14.2758 34.1974 12.7008 36.4024 9.25082 39.4174V40.2124ZM20.8561 40.4224C22.8211 40.4224 24.3961 39.2374 24.3961 37.2874C24.3961 35.7724 23.3461 34.7974 22.0561 34.4824V34.4224C23.2261 34.0024 24.0061 33.1174 24.0061 31.7824C24.0061 30.0274 22.6561 29.0224 20.8111 29.0224C19.5511 29.0224 18.5761 29.5774 17.7511 30.3274L18.4861 31.2124C19.1011 30.5824 19.8811 30.1324 20.7661 30.1324C21.9061 30.1324 22.6111 30.8224 22.6111 31.8724C22.6111 33.0724 21.8461 33.9724 19.5811 33.9724V35.0374C22.1161 35.0374 23.0011 35.9074 23.0011 37.2274C23.0011 38.4874 22.0711 39.2824 20.7661 39.2824C19.5061 39.2824 18.6811 38.6824 18.0361 38.0074L17.3461 38.9074C18.0661 39.7024 19.1311 40.4224 20.8561 40.4224Z" fill="#DADADA"/>
            </svg>            
    )
}

export default Window;

const WriterNickname = styled.p `
    margin-bottom: 10px;
    font-size: 12px;
    color: #6E6E6E;
`
const NumContainer = styled.div`
    
`