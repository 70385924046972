export const badwordexam = [
    'D쥐고',
    'D지고',
    'jonna',
    'jot같',
    'mi쳤',
    'tlqkf',
    'wlfkf',
    '같은 새끼',
    '같은새끼',
    '개 새끼',
    '개같',
    '개나대',
    '개넷',
    '개년',
    '개념빠가',
    '개련',
    '개련',
    '개부랄',
    '개새기',
    '개새끼',
    '개섹',
    '풀발',
    '씹선',
    '개소리',
    '개쓰래기',
    '개저씨',
    '거지같',
    '계새끼',
    '골 빈',
    '골1빈',
    '골빈',
    '괘새끼',
    '구1씹',
    '구씹',
    '그1켬',
    '그나물에',
    '그지 같',
    '그지같',
    '그켬',
    '극1혐',
    '극혐',
    '글러 먹',
    '글러먹',
    '기레기',
    '기자레기',
    '김치녀',
    '한녀',
    '된장녀',
    '앙기모띠',
    '소추',
    '퍄퍄',
    '눈나',
    '김여사',
    '보적보',
    '삼일한',
    '보슬아치',
    '보징어',
    '엑윽',
    '헤으응',
    '이기야',
    '노무',
    '부왘',
    '보픈카',
    '보라니',
    '상폐녀',
    '배빵',
    '누보햄',
    '찌질',
    '자박꼼',
    '로린 ',
    '펨베',
    '펨코',
    '허벌',
    '쿰.척',
    '쿰척',
    '까내리',
    '껒여',
    '꺼지세요',
    '꺼져요',
    '로 꺼져',
    '로꺼져',
    '로 꺼.져',
    '꺼.지',
    '꼬라지',
    '꼬라지',
    '꼴갑',
    '꼴값',
    '꼴깝',
    '꼴랑',
    '꼴보기',
    '꼴뵈기',
    '설거지론',
    '퐁퐁남',
    '퐁퐁녀',
    '나빼썅',
    '나쁜 새끼',
    '넌씨눈',
    '년놈',
    '노무노무',
    '노알라',
    '노친네',
    '느그',
    '느금',
    '뇌 텅',
    '뇌1텅',
    '뇌텅',
    '뇌피셜',
    '눈깔 파',
    '눈깔파',
    '눈새',
    '늬믜',
    '늬미',
    '니년',
    '니믜',
    '니미럴',
    '닝기리',
    'ㄷㅇㅂ',
    '다꺼져',
    '닥1',
    '닥2',
    '닥전',
    '닥쳐라',
    '닥치세',
    '닥후',
    '대가리',
    '대갈',
    '더럽네',
    '덜떨어',
    '덬',
    '도라이',
    '도랏',
    '도랐',
    '도른',
    '돌앗구만',
    '돌앗나',
    '돌앗네',
    '돌았구만',
    '돌았나',
    '돌았네',
    '둄마',
    '뒈져',
    '뒤져라',
    '뒤져버',
    '뒤져야',
    '뒤져야지',
    '뒤져요',
    '뒤졌',
    '뒤지겠',
    '뒤지고싶',
    '뒤지길',
    '뒤진다',
    '뒤질',
    '듣보',
    '디져라',
    '디졌',
    '디지고',
    '디질',
    '딴년',
    '또라이',
    '또라인',
    '똘아이',
    '뚝배기',
    '뚫린 입',
    '뚫린입',
    '런년',
    '레1친',
    '레기같',
    '레기네',
    '레기다',
    '레친',
    '롬들',
    'ㅁㅊ',
    'ㅁ친',
    '망돌',
    '망해라',
    '머갈',
    '머리 텅',
    '머리텅',
    '먹.금',
    '먹.끔',
    '먹1금',
    '먹금',
    '먹끔',
    '명존',
    '믜칀',
    '믜친',
    '미: 놈',
    '미:놈',
    '미1친',
    '미놈',
    '미시친발',
    '미쳣네',
    '미쳤나',
    '미쳤니',
    '미췬',
    '미칀',
    '미친 새',
    '미친~',
    '미친개',
    '미친새',
    '미친색',
    '미친ㅋ',
    '미틴',
    '및힌',
    '줘패',
    '꼬추',
    '미치ㄴ',
    'ㅅ.ㄲ',
    '색퀴',
    'ㅅ끼',
    '흉자',
    '미친넘',
    '미핀놈',
    '샛기',
    'G랄',
    '세키',
    '미치누',
    'd져',
    'ㅂㄹ',
    'ㅄ',
    'ㅂㅅ',
    'ㅂㅁㄱ',
    'ㅂㅊ',
    'ㅂ크',
    '발놈',
    '별창',
    '병1신',
    '병1크',
    '병맛',
    '병신',
    '병크',
    '봊',
    '보전깨',
    '싸개',
    '븅신',
    '빠큐',
    '빡새끼',
    '빻았',
    '빻은',
    '뻐규',
    '뻐큐',
    '뻑유',
    '뻑큐',
    '뻨큐',
    '뼈큐',
    '뽄새',
    '뽄세',
    '삐걱',
    '쉰내',
    'ㅄ',
    'ㅅ.ㅂ',
    'ㅅㅂ',
    'ㅅ1ㅂ',
    'ㅅ1발',
    'ㅅㄲ네',
    'ㅅㄲ들',
    '친ㅅㄲ',
    '친 ㅅㄲ',
    'ㅅ1ㄲ',
    'ㅅ루',
    'ㅅㅋ',
    'ㅅㅌㅊ',
    'ㅅㅡ루',
    '사새끼',
    '새.끼',
    '새1끼',
    '새1키',
    '새77ㅣ',
    '새끼라',
    '새끼야',
    '새퀴',
    '새킈',
    '새키',
    '색희',
    '색히',
    '샊기',
    '샊히',
    '샹년',
    '섀키',
    '성괴',
    '쉬발',
    '쉬버',
    '쉬이바',
    '쉬이이',
    '쉬이이이',
    '쉬펄',
    '슈1발',
    '슈레기',
    '슈발',
    '슈벌',
    '슈우벌',
    '슈ㅣ발',
    '스.루',
    '스ㄹㅜ',
    '스벌',
    '싑창',
    '시1발',
    '시미발친',
    '시미친발',
    '시바 ',
    '시바라지',
    '시바류',
    '시바시바',
    '시바알',
    '시바앙',
    '시발',
    '🖕',
    '시방새',
    '시벌탱',
    '시볼탱',
    '시부럴',
    '시부렬',
    '시부울',
    '시뷰럴',
    '시뷰렬',
    '시빨',
    '시새발끼',
    '시이발',
    '시친발미',
    '시팔',
    '시펄',
    '십창',
    '십팔',
    'ㅆ1ㄺ',
    'ㅆ1ㅂ',
    'ㅆㄹㄱ',
    'ㅆㄺ',
    'ㅆㅂ',
    '싸가지 없',
    '싸가지없',
    '싸물어',
    '쌍년',
    '쌍놈',
    '쌔끼',
    '썅',
    '썌끼',
    '쒸펄',
    '쓰1레기',
    '쓰래기같',
    '쓰레기 새',
    '쓰레기새',
    '쓰렉',
    '씝창',
    '씨1발',
    '씨바라',
    '씨바알',
    '씨발',
    '씨방새',
    '씨버럼',
    '씨벌',
    '씨벌탱',
    '씨볼탱',
    '씨부럴',
    '씨부렬',
    '씨뷰럴',
    '씨뷰렬',
    '씨빠빠',
    '씨빨',
    '씨뻘',
    '씨새발끼',
    '씨이발',
    '씨팔',
    '씹귀',
    '씹덕',
    '씹못',
    '씹뻐럴',
    '씹새끼',
    '씹쌔',
    '씹창',
    '씹치',
    '씹팔',
    '씹할',
    'ㅇㅍㅊㅌ',
    '아가리',
    '아닥',
    '아오 ㅅㅂ',
    '아오 시바',
    '아오ㅅㅂ',
    '아오시바',
    '애미',
    '앰',
    '앰창',
    '에라이 퉤',
    '에라이 퉷',
    '에라이퉤',
    '에라이퉷',
    '엠뷩신',
    '엠븽신',
    '엠빙신',
    '시녀',
    '엠생',
    '엠창',
    '엿같',
    '엿이나',
    '옘병',
    '오크',
    '와꾸',
    '외1퀴',
    '외퀴',
    '웅앵',
    '웅엥',
    '은년',
    '은새끼',
    '이 새끼',
    '이따위',
    '이새끼',
    '인간말종',
    '입 털',
    '입털',
    'ㅈ.ㄴ',
    'ㅈㄴ',
    'ㅈㄹ',
    '절라',
    '정병',
    '정신나갓',
    '정신나갔',
    '젖 같',
    '젗같',
    '젼나',
    '젼낰',
    '졀라',
    '졀리',
    '졌같은',
    '졏 같',
    '조낸',
    '조녜',
    '조온',
    '조온나',
    '족까',
    '존 나',
    '존 나',
    '존.나',
    '존1',
    '존1나',
    '🚬',
    '존귀',
    '존귘',
    '존ㄴ나',
    '존나',
    '존낙',
    '존내',
    '존똑',
    '존맛',
    '존멋',
    '존버',
    '존싫',
    '존웃',
    '존잘',
    '존잼',
    '존좋',
    '졸라',
    '졸맛',
    '졸멋',
    '졸싫',
    '졸예',
    '졸웃',
    '졸잼',
    '졸좋',
    '좁밥',
    '조센징',
    '짱깨',
    '짱개',
    '짱꼴라',
    '짱골라',
    '좃',
    '종나',
    '곱창났',
    '곱창나',
    '좆',
    '좆까',
    '좇같',
    '죠낸',
    '죠온나',
    '죤나',
    '죤내',
    '죵나',
    '죶',
    '죽어버려',
    '죽여 버리고',
    '죽여버리고',
    '죽여불고',
    '죽여뿌고',
    '줬같은',
    '쥐랄',
    '쥰나',
    '쥰내',
    '쥰니',
    '쥰트',
    '즤랄',
    '지 랄',
    '지1랄',
    '지1뢰',
    '지랄',
    '지롤',
    'ㅉ',
    'ㅉ질한',
    '짱깨',
    '짱께',
    '쪼녜',
    '쪼다',
    '착짱죽짱',
    '섬숭이',
    '쪽본',
    '쪽1바리',
    '쪽바리',
    '쪽발',
    '쫀 맛',
    '쫀1',
    '쫀귀',
    '쫀맛',
    '쫂',
    '쫓같',
    '쬰잘',
    '쬲',
    '쯰질',
    '찌1질',
    '찌질한',
    '찍찍이',
    '찎찎이',
    '찝째끼',
    '창년',
    '창녀',
    '창남',
    '창놈',
    '창넘',
    '처먹',
    '凸',
    '첫빠',
    '취좃',
    '친 년',
    '한 년',
    '친 놈',
    '친구년',
    '친년',
    '한년',
    '친노마',
    '친놈',
    '텐덕',
    '핑끄',
    '핑프',
    'ㅎㅃ',
    'ㅎㅌㅊ',
    '손놈',
    '남미새',
    '여미새',
    '호로새끼',
    '호로잡',
    '화낭년',
    '화냥년',
    '후.려',
    '후1려',
    '후1빨',
    '후려',
    '후빨',
    '미-친',
    '시-발',
    '지-랄',
    '개-새-끼',
    '또-라-이',
    '디-질',
    '걸-레',
    '닥-쳐',
    '등-신',
    '뇌-텅텅',
    '대-가-리',
    '병-신',
    '엠-창',
    '조-ㅈ',
    '염-병',
    '쌍-판',
    '쳐-',
    '씹-',
    'ㅈㄹ',
    'ㅂㅅ',
    'ㅆㅂ',
    'ㅅㅂ',
    '쒸발',
    '싸발',
    '섹',
    '섹스',
    'ㅃㅅ',
    'ㅂ1ㅅ',
    'ㅈ1ㄹ',
    'ㅅ1ㅂ',
    'ㅅㅃ',
    'ㅆㅃ',
    'ㅗ',
    'ㅗㅗ',
    'ㅗㅗㅗㅗㅗㅗㅗㅗ',
    '10새끼',
    '10쎄끼',
    '10알',
    '10창',
    '10탱',
    '10탱아',
    '10팔',
    '10팔년',
    '10팔놈',
    '10팔연',
    '10할년',
    '10할연',
    '18넘',
    '18년',
    '18놈',
    '18새끼',
    'ㄱㅐㅅㅐㄲl',
    'ㄱㅐㅈㅏ',
    '가슴만져',
    '가슴빨아',
    '가슴빨어',
    '가슴조물락',
    '가슴주물럭',
    '가슴쪼물딱',
    '가슴쪼물락',
    '가슴핧아',
    '가슴핧어',
    '강간',
    '개가튼년',
    '개가튼뇬',
    '개같은년',
    '개걸레',
    '개고치',
    '개너미',
    '개넘',
    '개년',
    '개놈',
    '개늠',
    '개똥',
    '개떵',
    '개떡',
    '개라슥',
    '개보지',
    '개부달',
    '개부랄',
    '개불랄',
    '개붕알',
    '개새',
    '개세',
    '개쓰래기',
    '개쓰레기',
    '개씁년',
    '개씁블',
    '개씁자지',
    '개씨발',
    '개씨블',
    '개자식',
    '개자지',
    '개잡년',
    '개젓가튼넘',
    '개좆',
    '개지랄',
    '개후라년',
    '개후라들놈',
    '개후라새끼',
    '걔잡년',
    '거시기',
    '걸래년',
    '걸레같은년',
    '걸레년',
    '걸레핀년',
    '게부럴',
    '게세끼',
    '게이',
    '게새끼',
    '게늠',
    '게자식',
    '게지랄놈',
    '고환',
    '공지',
    '공지사항',
    '귀두',
    '깨쌔끼',
    '난자마셔',
    '난자먹어',
    '난자핧아',
    '내꺼빨아',
    '내꺼핧아',
    '내버지',
    '내자지',
    '내잠지',
    '내조지',
    '너거애비',
    '노옴',
    '누나강간',
    '니기미',
    '니뿡',
    '니뽕',
    '니씨브랄',
    '니아범',
    '니아비',
    '니애미',
    '니애뷔',
    '니애비',
    '니할애비',
    '닝기미',
    '닌기미',
    '니미',
    '닳은년',
    '덜은새끼',
    '돈새끼',
    '돌으년',
    '돌은넘',
    '돌은새끼',
    '동생강간',
    '동성애자',
    '딸딸이',
    '똥구녁',
    '똥꾸뇽',
    '똥구뇽',
    '똥',
    '띠발뇬',
    '띠팔',
    '띠펄',
    '띠풀',
    '띠벌',
    '띠벨',
    '띠빌',
    '마스터',
    '막간년',
    '막대쑤셔줘',
    '막대핧아줘',
    '맛간년',
    '맛없는년',
    '맛이간년',
    '멜리스',
    '미친구녕',
    '미친구멍',
    '미친넘',
    'ㅁㅊ',
    'ㅁㅊㄴ',
    'ㅁㅊㅁㅊ',
    'ㅁㅊㄴㅁㅊㄴ',
    '미친년',
    '미친놈',
    '미친눔',
    '미친새끼',
    '미친쇄리',
    '미친쇠리',
    '미친쉐이',
    '미친씨부랄',
    '미튄',
    '미티넘',
    '미틴',
    '미틴넘',
    '미틴년',
    '미틴놈',
    '미틴것',
    '백보지',
    '버따리자지',
    '버지구녕',
    '버지구멍',
    '버지냄새',
    '버지따먹기',
    '버지뚫어',
    '버지뜨더',
    '버지물마셔',
    '버지벌려',
    '버지벌료',
    '버지빨아',
    '버지빨어',
    '버지썰어',
    '버지쑤셔',
    '버지털',
    '버지핧아',
    '버짓물',
    '버짓물마셔',
    '벌창같은년',
    '벵신',
    '병닥',
    '병딱',
    '병신',
    '보쥐',
    '보지',
    '보지핧어',
    '보짓물',
    '보짓물마셔',
    '봉알',
    '부랄',
    '불알',
    '붕알',
    '붜지',
    '뷩딱',
    '븅쉰',
    '븅신',
    '빙띤',
    '빙신',
    '빠가십새',
    '빠가씹새',
    '빠구리',
    '빠굴이',
    '뻑큐',
    '뽕알',
    '뽀지',
    '뼝신',
    '사까시',
    '상년',
    '새꺄',
    '새뀌',
    '새끼',
    '색갸',
    '색끼',
    '색스',
    '색키',
    '샤발',
    '서버',
    '써글',
    '써글년',
    '성교',
    '성폭행',
    '세꺄',
    '세끼',
    '섹스',
    '섹스하자',
    '섹스해',
    'ㅅㅅ하자',
    'ㅅㅅ해',
    'ㅅㅅ',
    '섹쓰',
    '섹히',
    '수셔',
    '쑤셔',
    '쉐끼',
    '쉑갸',
    '쉑쓰',
    '쉬발',
    '쉬방',
    '쉬밸년',
    '쉬벌',
    '쉬불',
    '쉬붕',
    '쉬빨',
    '쉬이발',
    '쉬이방',
    '쉬이벌',
    '쉬이불',
    '쉬이붕',
    '쉬이빨',
    '쉬이팔',
    '쉬이펄',
    '쉬이풀',
    '쉬팔',
    '쉬펄',
    '쉬풀',
    '쉽쌔',
    '시댕이',
    '시발',
    '시발년',
    '시발놈',
    '시발새끼',
    '시방새',
    '시밸',
    '시벌',
    '시불',
    '시붕',
    '시이발',
    '시이벌',
    '시이불',
    '시이붕',
    '시이팔',
    '시이펄',
    '시이풀',
    '시팍새끼',
    '시팔',
    '시팔넘',
    '시팔년',
    '시팔놈',
    '시팔새끼',
    '시펄',
    '실프',
    '십8',
    '십때끼',
    '십떼끼',
    '십버지',
    '십부랄',
    '십부럴',
    '십새',
    '십세이',
    '십셰리',
    '십쉐',
    '십자석',
    '십자슥',
    '십지랄',
    '십창녀',
    '십창',
    '십탱',
    '십탱구리',
    '십탱굴이',
    '십팔새끼',
    'ㅆㅂ',
    'ㅆㅂㄹㅁ',
    'ㅆㅂㄻ',
    'ㅆㅣ',
    '쌍넘',
    '쌍년',
    '쌍놈',
    '쌍눔',
    '쌍보지',
    '쌔끼',
    '쌔리',
    '쌕스',
    '쌕쓰',
    '썅년',
    '썅놈',
    '썅뇬',
    '썅늠',
    '쓉새',
    '쓰바새끼',
    '쓰브랄쉽세',
    '씌발',
    '씌팔',
    '씨가랭넘',
    '씨가랭년',
    '씨가랭놈',
    '씨발',
    '씨발년',
    '씨발롬',
    '씨발병신',
    '씨방새',
    '씨방세',
    '씨밸',
    '씨뱅가리',
    '씨벌',
    '씨벌년',
    '씨벌쉐이',
    '씨부랄',
    '씨부럴',
    '씨불',
    '씨불알',
    '씨붕',
    '씨브럴',
    '씨블',
    '씨블년',
    '씨븡새끼',
    '씨빨',
    '씨이발',
    '씨이벌',
    '씨이불',
    '씨이붕',
    '씨이팔',
    '씨파넘',
    '씨팍새끼',
    '씨팍세끼',
    '씨팔',
    '씨펄',
    '씨퐁넘',
    '씨퐁뇬',
    '씨퐁보지',
    '씨퐁자지',
    '씹년',
    '씹물',
    '씹미랄',
    '씹버지',
    '씹보지',
    '씹부랄',
    '씹브랄',
    '씹빵구',
    '씹뽀지',
    '씹새',
    '씹새끼',
    '씹세',
    '씹쌔끼',
    '씹자석',
    '씹자슥',
    '씹자지',
    '씹지랄',
    '씹창',
    '씹창녀',
    '씹탱',
    '씹탱굴이',
    '씹탱이',
    '씹팔',
    '아가리',
    '애무',
    '애미',
    '애미랄',
    '애미보지',
    '애미씨뱅',
    '애미자지',
    '애미잡년',
    '애미좃물',
    '애비',
    '애자',
    '양아치',
    '어미강간',
    '어미따먹자',
    '어미쑤시자',
    '영자',
    '엄창',
    '에미',
    '에비',
    '엔플레버',
    '엠플레버',
    '염병',
    '염병할',
    '염뵹',
    '엿먹어라',
    '오랄',
    '오르가즘',
    '왕버지',
    '왕자지',
    '왕잠지',
    '왕털버지',
    '왕털보지',
    '왕털자지',
    '왕털잠지',
    '우미쑤셔',
    '운디네',
    '운영자',
    '유두',
    '유두빨어',
    '유두핧어',
    '유방',
    '유방만져',
    '유방빨아',
    '유방주물럭',
    '유방쪼물딱',
    '유방쪼물럭',
    '유방핧아',
    '유방핧어',
    '육갑',
    '이그니스',
    '이년',
    '이프리트',
    '자기핧아',
    '자지',
    '자지구녕',
    '자지구멍',
    '자지꽂아',
    '자지넣자',
    '자지뜨더',
    '자지뜯어',
    '자지박어',
    '자지빨아',
    '자지빨아줘',
    '자지빨어',
    '자지쑤셔',
    '자지쓰레기',
    '자지정개',
    '자지짤라',
    '자지털',
    '자지핧아',
    '자지핧아줘',
    '자지핧어',
    '작은보지',
    '잠지',
    '잠지뚫어',
    '잠지물마셔',
    '잠지털',
    '잠짓물마셔',
    '잡년',
    '잡놈',
    '저년',
    '점물',
    '젓가튼',
    '젓가튼쉐이',
    '젓같내',
    '젓같은',
    '젓까',
    '젓나',
    '젓냄새',
    '젓대가리',
    '젓떠',
    '젓마무리',
    '젓만이',
    '젓물',
    '젓물냄새',
    '젓밥',
    '정액마셔',
    '정액먹어',
    '정액발사',
    '정액짜',
    '정액핧아',
    '정자마셔',
    '정자먹어',
    '정자핧아',
    '젖같은',
    '젖까',
    '젖밥',
    '젖탱이',
    '조개넓은년',
    '조개따조',
    '조개마셔줘',
    '조개벌려조',
    '조개속물',
    '조개쑤셔줘',
    '조개핧아줘',
    '조까',
    '조또',
    '족같내',
    '족까',
    '족까내',
    '존나',
    '존나게',
    '존니',
    '졸라',
    '좀마니',
    '좀물',
    '좀쓰레기',
    '좁빠라라',
    '좃가튼뇬',
    '좃간년',
    '좃까',
    '좃까리',
    '좃깟네',
    '좃냄새',
    '좃넘',
    '좃대가리',
    '좃도',
    '좃또',
    '좃만아',
    '좃만이',
    '좃만한것',
    '좃만한쉐이',
    '좃물',
    '좃물냄새',
    '좃보지',
    '좃부랄',
    '좃빠구리',
    '좃빠네',
    '좃빠라라',
    '좃털',
    '좆같은놈',
    '좆같은새끼',
    '좆까',
    '좆까라',
    '좆나',
    '좆년',
    '좆도',
    '좆만아',
    '좆만한년',
    '좆만한놈',
    '좆만한새끼',
    '좆먹어',
    '좆물',
    '좆밥',
    '좆빨아',
    '좆새끼',
    '좆털',
    '좋만한것',
    '주글년',
    '주길년',
    '쥐랄',
    '지랄',
    '지랼',
    '지럴',
    '지뢀',
    '쪼까튼',
    '쪼다',
    '쪼다새끼',
    '찌랄',
    '찌질이',
    '창남',
    '창녀',
    '창녀버지',
    '창년',
    '처먹고',
    '처먹을',
    '쳐먹고',
    '쳐쑤셔박어',
    '촌씨브라리',
    '촌씨브랑이',
    '촌씨브랭이',
    '크리토리스',
    '큰보지',
    '클리토리스',
    '트랜스젠더',
    '페니스',
    '항문수셔',
    '항문쑤셔',
    '허덥',
    '허버리년',
    '허벌년',
    '허벌보지',
    '허벌자식',
    '허벌자지',
    '허접',
    '허젚',
    '허졉',
    '허좁',
    '헐렁보지',
    '혀로보지핧기',
    '호냥년',
    '호로',
    '호로새끼',
    '호로자슥',
    '호로자식',
    '호로짜식',
    '호루자슥',
    '호모',
    '호졉',
    '호좁',
    '후라덜넘',
    '후장',
    '후장꽂아',
    '후장뚫어',
    '흐접',
    '흐젚',
    '흐졉',
    'bitch',
    'fuck',
    'fuckyou',
    'nflavor',
    'penis',
    'pennis',
    'pussy',
    'sex',
    '개씨발년',
    '머리채',
    '존나하고싶다',
    '음란',
    '갱뱅',
    '갱뱅쳐서',
    '자궁',
    '자궁 애기',
    '자궁애기',
    '애기씨',
    '애기씨 듬뿍',
    '애기씨듬뿍',
    '정액 듬뿍',
    '정액듬뿍',
    '임신시켜서',
    '노예만들',
    '존나따',
    '존나따먹',
    '존나따먹고',
    '따먹고싶다',
    '따먹고 싶다',
    '존나 따먹고 싶다',
    '존나 따먹고싶다',
    '욕구',
    '클리',
    '분수터',
    '분수터지',
    '애기가지게',
    '애기갖게',
    '애기가지',
    '개병',
    '개미친',
    '개미친년',
    'ㅁㅣ친',
    '시 발 아',
    '시 발 놈',
    '시 발 년',
    '시 발놈',
    '시발 놈',
    '시발 년',
    '시 발년',
    '임신시',
];