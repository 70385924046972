const GingerDate = ({info, item}) => {
  return (
    item == 1 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-01</div>
    :
    item == 2 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-02</div>
    :
    item == 3 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-03</div>
    :
    item == 4 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-04</div>
    :
    item == 5 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-05</div>
    :
    item == 6 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-06</div>
    :
    item == 7 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-07</div>
    :
    item == 8 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-08</div>
    :
    item == 9 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-09</div>
    :
    item == 10 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-10</div>
    :
    item == 11 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-11</div>
    :
    item == 12 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-12</div>
    :
    item == 13 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-13</div>
    :
    item == 14 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-14</div>
    :
    item == 15 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-15</div>
    :
    item == 16 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-16</div>
    :
    item == 17 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-17</div>
    :
    item == 18 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-18</div>
    :
    item == 19 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-19</div>
    :
    item == 20 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-20</div>
    :
    item == 21 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-21</div>
    :
    item == 22 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-22</div>
    :
    item == 23 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-23</div>
    :
    item == 24 ?
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-24</div>
    :
    <div style={{fontFamily: 'humanbeomseok',
    fontSize: "13px",
    lineheight: "11px",
    textAlign: "center"}
    }>2022-12-25</div>
  )
}

export default GingerDate